import React, { useRef, useState, useEffect, useContext } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useLocation, Link, useParams } from "react-router-dom";
import Nav from "../components/Nav";
import PropertyInformation from "../components/PropertyInformation";
import Tab from "../components/Tab";
import { AuthContext } from "../providers/Authentication";
import { db, storage } from "../firebase";
import HomeDetails from "../components/HomeDetails";
import HomeDescriptions from "../components/HomeDescriptions";
import HomeProperties from "../components/HomeProperties";
import HomeStatus from "../components/HomeStatus";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

import Gallery from "react-photo-gallery";
import Photo from "../components/Photo";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Footer from "../components/Footer";
import HelmetMetaData from "../providers/HelmetMetaData";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import axios from "axios";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items, own, onClick }) => (
  <Gallery
    photos={items}
    renderImage={(props) => (
      <SortablePhoto {...props} own={own} onClick={(data) => onClick(data)} />
    )}
  />
));

const ListingPage = () => {
  const history = useHistory();
  let { id } = useParams();
  const { state: miles } = useLocation();
  const [imagesData, setImagesData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [userType, setUserType] = useState("");
  const [own, setOwn] = useState(false);
  const [inFeaturesList, setInFeaturesList] = useState([]);
  const [exFeaturesList, setExFeaturesList] = useState([]);
  const [prFeaturesList, setPrFeaturesList] = useState([]);
  const [save, setSave] = useState(false);
  const [share, setShare] = useState(false);
  const modalImgsRef = useRef(null);
  const [showImgs, setShowImgs] = useState(false);
  const [homeData, setHomeData] = useState({
    address: "",
    bathrooms: "",
    bedrooms: "",
    descriptions: "",
    images: [],
  });
  const { images } = homeData;
  const childRef = useRef();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [campaignId, setCampaignId] = useState("");

  const priceRef = useRef(null);

  useEffect(() => {
    getHomeData();
    getRequestHomes(id);
  }, [id]);

  const getRequestHomes = async (id) => {
    if (currentUser) {
      let user = await db
        .collection("users")
        .where("uid", "==", currentUser.UserUid)
        .get();
      const data = user.docs[0].data();
      const requestHomes = data.requestHomes || [];
      if (requestHomes.length === 0) {
        setShowRequest(false);
      } else {
        const result = requestHomes.filter((item) => item === id);
        if (result.length > 0) setShowRequest(true);
        else setShowRequest(false);
      }
    } else {
      setShowRequest(false);
    }
  };

  const getHomeData = async () => {
    let homeItem = await db.collection("homes").where("homeid", "==", id).get();

    if (!homeItem.docs[0]) {
      history.push("/");
      return;
    }

    const data = homeItem.docs[0].data();
    setHomeData(data);

    if (!!data.images) {
      const newData = data.images.map((item) => {
        return {
          src: item,
          width: 1,
          height: 1,
        };
      });
      setImagesData(newData);
    }

    if (!!currentUser && data.sellerId === currentUser.UserUid) {
      setOwn(true);
    }
    getUserType();

    getFeatureList();

    if (miles === "") {
      childRef.current.showModal();
    }
  };

  const getUserType = async () => {
    if (!!currentUser) {
      const query = await db
        .collection("users")
        .where("uid", "==", currentUser.UserUid)
        .get();
      if (query.docs.length > 0) {
        const data = await query.docs[0].data();
        setCampaignId(data.campaignId);
        setUserType(data.type);
        if (data.type === "admin") {
          setOwn(true);
        }
      }
    }
  };

  const getFeatureList = async () => {
    const query = await db.collection("featureCategories").get();
    const newInData = [];
    const newExData = [];
    const newPrData = [];
    if (query.docs.length > 0) {
      for (let i = 0; i < query.docs.length; i++) {
        let data = await query.docs[i].data();
        if (data.category === "Interior") {
          newInData.push(data);
        } else if (data.category === "Exterior") {
          newExData.push(data);
        } else if (data.category === "Property") {
          newPrData.push(data);
        }
      }
      setInFeaturesList(newInData);
      setExFeaturesList(newExData);
      setPrFeaturesList(newPrData);
    }
  };

  const saveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSave(!save);
  };

  const shareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShare(!share);
  };

  const showImgsModal = () => {
    if (!!images && images.length > 0) {
      setShowImgs(true);
    }
  };

  const hideImgsModal = () => {
    setShowImgs(false);
  };

  const onSaveData = async (data, homeid) => {
    try {
      let homeItem = await db
        .collection("homes")
        .where("homeid", "==", homeid)
        .get();
      let homeDocId = homeItem.docs[0].id;

      const today = new Date().toDateString();

      if (miles === "") {
        if (priceRef.current === null) {
          // const dealRes = await axios
          //   .post(
          //     "https://us-central1-smartmktg.cloudfunctions.net/createDeal",
          //     {
          //       deal: {
          //         title: data.address,
          //         contact: campaignId,
          //         value: (parseInt(data.price) * 100).toString(),
          //         propertyID: homeid,
          //         propertyCreated: today,
          //       }
          //     },
          //     { headers: {
          //       "Content-Type": "application/json",
          //     } },
          //   );

          // let dealId = dealRes.data.dealCustomFieldDatum.dealId
          let updatedHomeData = {
            ...data,
            // dealId: dealId
          };
          setHomeData(updatedHomeData);
          await db.collection("homes").doc(homeDocId).update(updatedHomeData);

          priceRef.current = data.price;
        } else {
          if (data.price !== priceRef.current) {
            let preDealId = homeData.dealId;
            // const dealRes = await axios
            //   .post(
            //     "https://us-central1-smartmktg.cloudfunctions.net/updateDeal",
            //     {
            //       deal: {
            //         id: preDealId,
            //         value: (parseInt(data.price) * 100).toString(),
            //       }
            //     },
            //     { headers: {
            //       "Content-Type": "application/json",
            //     } },
            //   );

            // let dealId = dealRes.data.deal.id
            let updatedHomeData = {
              ...data,
              // dealId: dealId
            };
            setHomeData(updatedHomeData);
            await db.collection("homes").doc(homeDocId).update(updatedHomeData);

            priceRef.current = data.price;
          } else {
            setHomeData(data);
            await db.collection("homes").doc(homeDocId).update(data);
          }
        }
      } else {
        if (data.price !== priceRef.current) {
          let preDealId = homeData.dealId;
          // const dealRes = await axios
          //   .post(
          //     "https://us-central1-smartmktg.cloudfunctions.net/updateDeal",
          //     // "http://localhost:5000/smartmktg/us-central1/updateDeal",
          //     {
          //       deal: {
          //         id: preDealId,
          //         value: (parseInt(data.price) * 100).toString(),
          //       }
          //     },
          //     { headers: {
          //       "Content-Type": "application/json",
          //     } },
          //   );

          // let dealId = dealRes.data.deal.id
          let updatedHomeData = {
            ...data,
            // dealId: dealId
          };
          setHomeData(updatedHomeData);
          await db.collection("homes").doc(homeDocId).update(updatedHomeData);

          priceRef.current = data.price;
        } else {
          setHomeData(data);
          await db.collection("homes").doc(homeDocId).update(data);
        }
      }
    } catch (error) {}
  };

  const onMapData = (data) => {
    history.push({
      pathname: "/map",
      state: data,
    });
  };

  const upload = (event) => {
    const fileInputElement = fileInputRef.current;
    fileInputElement.click();
  };

  const handleChange = async (e) => {
    setLoading(true);
    const file = Array.from(e.target.files);
    let fileUrls = [];
    if (file.length > 0) {
      for (const item of file) {
        let date = new Date().getTime();
        await storage
          .ref("HOMES")
          .child(`${currentUser.UserUid}/${date}-${item.name}`)
          .put(item);
        const fileUrl = await storage
          .ref("HOMES")
          .child(`${currentUser.UserUid}/${date}-${item.name}`)
          .getDownloadURL();
        fileUrls.push(fileUrl);
      }
      let newImgs =
        !!homeData.images && homeData.images.length > 0
          ? [...homeData.images, ...fileUrls]
          : fileUrls;
      let newImgData = newImgs.map((item) => {
        return {
          src: item,
          width: 1,
          height: 1,
        };
      });
      setImagesData(newImgData);
      let tempHomeData = {
        ...homeData,
        images: newImgs,
      };
      onSaveData(tempHomeData, id);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if ((userType === "Selling my home" || userType === "admin") && own) {
      setImagesData(arrayMoveImmutable(imagesData, oldIndex, newIndex));
      let newArr = arrayMoveImmutable(imagesData, oldIndex, newIndex);
      const newImages = newArr.map((item) => {
        return item.src;
      });
      let tempHomeData = {
        ...homeData,
        images: newImages,
      };
      onSaveData(tempHomeData, id);
    }
  };

  const onDeleteImg = (data) => {
    let imgArray = homeData.images;
    let index = imgArray.indexOf(data.src);
    if (index !== -1) {
      imgArray.splice(index, 1);
      let newImgData = imgArray.map((item) => {
        return {
          src: item,
          width: 1,
          height: 1,
        };
      });
      setImagesData(newImgData);
      let tempHomeData = {
        ...homeData,
        images: imgArray,
      };
      onSaveData(tempHomeData, id);
    }
  };

  const callback = (result) => {
    if (result === true) {
      setShowRequest(true);
    } else {
      setShowRequest(false);
    }
  };

  return (
    <div className="w-full">
      <HelmetMetaData
        listingId={id}
        title={`For Sale - ${
          homeData && homeData.address ? homeData.address : ""
        }`}
        image={
          homeData && homeData.images && homeData.images.length > 0
            ? homeData.images[0]
            : ""
        }
      ></HelmetMetaData>
      <Nav />
      <div className="max-w-7xl block mx-auto py-2 mt-1 px-4 sm:px-6 lg:px-8">
        <Link to="/gallery" className="text-dark">
          <i className="fa fa-chevron-left"></i>
          <span className="text-dark ml-1">Back to search</span>
        </Link>
      </div>
      <div
        className="max-w-7xl block md:flex items-center mx-auto py-2 mt-1 mb-4 px-4 sm:px-6 lg:px-8"
        onClick={() => showImgsModal()}
      >
        <div className="w-full md:w-2/3 relative border rounded">
          <img
            src={
              !!images && !!images[0] ? images[0] : "../assets/images/empty.png"
            }
            alt="left-home1"
            className="w-full p-1 object-contain"
          />
          {!!miles && (
            <div className="absolute bg-dark text-white rounded top-3.5 left-3.5 px-4 py-1 flex items-center">
              <img
                className="w-6"
                alt="pin_white1"
                src="../assets/icons/pin_white.png"
              />
              <span className="pl-2">{miles} mi</span>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/3 relative">
          <img
            src={
              !!images && !!images[1]
                ? images[1]
                : !!images && !!images[0]
                ? images[0]
                : "../assets/images/empty.png"
            }
            alt="right-home2"
            className="w-full p-1 object-contain border rounded"
          />
          <div className="absolute top-3.5 left-3.5 flex items-center">
            <div
              onClick={(e) => saveClick(e)}
              className="bg-white text-dark rounded px-4 py-1 flex items-center cursor-pointer"
            >
              <img
                className="w-4"
                alt="favourite1"
                src={
                  save
                    ? "../assets/icons/favourite1.png"
                    : "../assets/icons/favourite0.png"
                }
              />
              <span className="ml-2">Save</span>
            </div>
            <div
              onClick={(e) => shareClick(e)}
              className="bg-white text-dark rounded px-4 py-1 flex items-center ml-2 cursor-pointer relative"
            >
              <img
                className="w-4"
                alt="share1"
                src={
                  share
                    ? "../assets/icons/share1.png"
                    : "../assets/icons/share0.png"
                }
              />
              <span className="ml-2">Share</span>
              {share && (
                <div className="absolute h-10 bottom-10 left-0 flex">
                  <FacebookShareButton
                    url={"https://smartmktg.netlify.app/profile/" + id}
                    quote={`For Sale - ${
                      homeData && homeData.address ? homeData.address : ""
                    }`}
                    hashtag="#smart"
                  >
                    <FacebookIcon size={36} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={"https://smartmktg.netlify.app/profile/" + id}
                    title={`For Sale - ${
                      homeData && homeData.address ? homeData.address : ""
                    }`}
                    hashtag="#smart"
                  >
                    <TwitterIcon size={36} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={"https://smartmktg.netlify.app/profile/" + id}
                    title={`For Sale - ${
                      homeData && homeData.address ? homeData.address : ""
                    }`}
                    separator=":: "
                  >
                    <WhatsappIcon size={36} />
                  </WhatsappShareButton>
                </div>
              )}
            </div>
          </div>
          <img
            src={
              !!images && !!images[2]
                ? images[2]
                : !!images && !!images[0]
                ? images[0]
                : "../assets/images/empty.png"
            }
            alt="right-home3"
            className="w-full p-1 object-contain border rounded"
          />
        </div>
      </div>
      <PuffLoader
        color={"#378ABA"}
        loading={loading}
        css={override}
        size={50}
      />
      {(userType === "Selling my home" || userType === "admin") && own && (
        <div className="max-w-7xl block items-center mx-auto text-center">
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleChange(e)}
            // accept=".png,.jpg,.gif,.PNG,.pdf,.PDF"
            className="hidden"
            multiple
          />
          <button
            className="w-56 text-xl bg-primary text-white rounded text-center cursor-pointer py-2"
            onClick={(event) => upload(event)}
          >
            Upload Photos
          </button>
        </div>
      )}
      <div className="max-w-7xl block md:flex mx-auto py-2 mt-1 px-4 sm:px-6 lg:px-8">
        <div className="w-full md:w-2/3 pt-3">
          <HomeDetails
            home={homeData}
            userType={userType}
            own={own}
            onSave={(data) => onSaveData(data, id)}
            onMap={(data) => onMapData(data)}
            ref={childRef}
          />
          <HomeDescriptions
            home={homeData}
            userType={userType}
            own={own}
            onSave={(data) => onSaveData(data, id)}
          />
          <HomeProperties home={homeData} />
        </div>
        <div className="w-full md:w-1/3 p-3">
          <HomeStatus
            home={homeData}
            userType={userType}
            own={own}
            onSave={(data) => onSaveData(data, id)}
          />
          {showRequest === false ? (
            <Tab home={homeData} parentCallback={callback} />
          ) : (
            <div className="text-center p-4">
              <h1 className="text-3xl font-bold text-primary p-1">
                Thank you!
              </h1>
              <p className="text-lg p-1 mt-4">
                Thank you for your interest in this property, you will receive a
                response via email soon.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="max-w-7xl block mx-auto border-b border-dark-01 pt-2 pb-10 mt-2 px-4 sm:px-6 lg:px-8">
        <div className="w-full mt-7 bg-light-blue-03 rounded p-3">
          <span className="text-xl text-dark font-bold">Interior Features</span>
        </div>
        {!!inFeaturesList &&
          inFeaturesList.map((item, i) => (
            <PropertyInformation
              key={i}
              globalFeature={item}
              home={homeData}
              userType={userType}
              own={own}
              onSave={(data) => onSaveData(data, id)}
            />
          ))}
        <div className="w-full mt-7 bg-light-blue-03 rounded p-3">
          <span className="text-xl text-dark font-bold">Exterior Features</span>
        </div>
        {!!exFeaturesList &&
          exFeaturesList.map((item, i) => (
            <PropertyInformation
              key={i}
              globalFeature={item}
              home={homeData}
              userType={userType}
              own={own}
              onSave={(data) => onSaveData(data, id)}
            />
          ))}
        <div className="w-full mt-7 bg-light-blue-03 rounded p-3">
          <span className="text-xl text-dark font-bold">
            Property Information
          </span>
        </div>
        {!!prFeaturesList &&
          prFeaturesList.map((item, i) => (
            <PropertyInformation
              key={i}
              globalFeature={item}
              home={homeData}
              userType={userType}
              own={own}
              onSave={(data) => onSaveData(data, id)}
            />
          ))}
      </div>
      <Modal
        ref={modalImgsRef}
        open={showImgs}
        center
        initialFocusRef={modalImgsRef}
        onClose={hideImgsModal}
        animationDuration={400}
      >
        <div className="text-center my-5">
          {imagesData.length > 0 && (
            <>
              <SortableGallery
                items={imagesData}
                own={own}
                onClick={(data) => onDeleteImg(data)}
                onSortEnd={onSortEnd}
                axis={"xy"}
              />
              {(userType === "Selling my home" || userType === "admin") &&
                own && (
                  <h3 className="mt-4 text-lg">
                    To rearrange your images, click and drag them to display in
                    your preferred order.
                  </h3>
                )}
            </>
          )}
        </div>
      </Modal>
      <Footer />
    </div>
  );
};

export default ListingPage;
