import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setTimeout(() => {
          setCurrentUser({UserUid: user.uid});
          setPending(false);
        }, 500);
      } else {
        setCurrentUser(null);
        setPending(false);
      }
    });
  }, []);

  if(pending){
    return <></>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};