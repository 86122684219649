import React, { useState, useRef, useEffect, createRef } from "react";
import { createPopper } from "@popperjs/core";

const FilterPriceRange = ({parentCallback}) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [minValue, setMinValue] = useState('0');
  const [maxValue, setMaxValue] = useState('Any');
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const container = useRef(null);

  useEffect(()=> {
    const handleOutsideClick = (event) => {
      if(!!container.current) {
        if (!container.current.contains(event.target)) {
          if (!dropdownPopoverShow) return;
          setDropdownPopoverShow(false);
        }
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [dropdownPopoverShow, container]);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleChangeMax = (e) => {
    setMaxValue(e.target.value)
  }

  const handleChangeMin = (e) => {
    setMinValue(e.target.value)
  }

  const handleClickApply = (e) => {
    e.preventDefault();
    setDropdownPopoverShow(false);

    parentCallback(minValue, maxValue);
  }
  
  return (
    <>
      <div ref={container} className="inline-block sm:flex flex-wrap mr-0 sm:mr-1 my-2">
        <div className="w-full sm:w-6/12 md:w-4/12 px-0">
          <div className="relative inline-flex align-middle w-full h-12">
            <button
              className={
                (dropdownPopoverShow ? "text-white bg-primary " : "text-primary bg-white") +
                "font-bold text-base pl-5 pr-10 py-1 rounded shadow hover:shadow-lg outline-none border border-primary focus:outline-none mr-1 mb-1 relative h-12"
              }
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              Price&nbsp;Range
              <span className={
                (dropdownPopoverShow ? "text-white " : "text-primary ") +
                "z-10 h-full leading-snug font-normal absolute text-center absolute text-base items-center w-10 right-0"}>
                <i className={(dropdownPopoverShow ? "fa-caret-up " : "fa-caret-down ") + "fa"}></i>
              </span>
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                "bg-white text-base z-50 float-left p-4 list-none text-left rounded shadow-primary mt-1"
              }
              style={{ minWidth: "12rem", zIndex: 10 }}
            >
              <div className="flex pb-2 border-b">
                <div className="block w-28 text-center pr-2">
                  <span className="text-dark font-medium">MIN</span>
                  <input value={minValue} type="text" placeholder="0" className="w-full pl-2 bg-white rounded text-base border border-primary outline-none focus:outline-none focus:ring"/>
                  <select onChange={handleChangeMin} className="form-multiselect block w-full h-48 mt-2 border outline-none focus:outline-none focus:ring" multiple>
                    <option value='0' className="border-b text-dark font-light text-xs px-4 py-2">$0</option>
                    <option value='50000' className="border-b text-dark font-light text-xs px-4 py-2">$50k</option>
                    <option value='100000' className="border-b text-dark font-light text-xs px-4 py-2">$100k</option>
                    <option value='150000' className="border-b text-dark font-light text-xs px-4 py-2">$150k</option>
                    <option value='200000' className="border-b text-dark font-light text-xs px-4 py-2">$200k</option>
                    <option value='250000' className="border-b text-dark font-light text-xs px-4 py-2">$250k</option>
                    <option value='300000' className="border-b text-dark font-light text-xs px-4 py-2">$300k</option>
                    <option value='350000' className="border-b text-dark font-light text-xs px-4 py-2">$350k</option>
                    <option value='400000' className="border-b text-dark font-light text-xs px-4 py-2">$400k</option>
                    <option value='450000' className="border-b text-dark font-light text-xs px-4 py-2">$450k</option>
                    <option value='500000' className="border-b text-dark font-light text-xs px-4 py-2">$500k</option>
                    <option value='550000' className="border-b text-dark font-light text-xs px-4 py-2">$550k</option>
                    <option value='600000' className="border-b text-dark font-light text-xs px-4 py-2">$600k</option>
                    <option value='660000' className="border-b text-dark font-light text-xs px-4 py-2">$650k</option>
                    <option value='700000' className="border-b text-dark font-light text-xs px-4 py-2">$700k</option>
                    <option value='750000' className="border-b text-dark font-light text-xs px-4 py-2">$750k</option>
                    <option value='800000' className="border-b text-dark font-light text-xs px-4 py-2">$800k</option>
                    <option value='850000' className="border-b text-dark font-light text-xs px-4 py-2">$850k</option>
                    <option value='900000' className="border-b text-dark font-light text-xs px-4 py-2">$900k</option>
                    <option value='950000' className="border-b text-dark font-light text-xs px-4 py-2">$950k</option>
                    <option value='1000000' className="border-b text-dark font-light text-xs px-4 py-2">$1m</option>
                  </select>
                </div>
                <div className="block w-28 text-center pl-2">
                  <span className="text-dark font-medium">MAX</span>
                  <input value={maxValue} type="text" placeholder="Any" className="w-full pl-2 bg-white rounded text-base border border-primary outline-none focus:outline-none focus:ring"/>
                  <select onChange={handleChangeMax} className="form-multiselect block w-full h-48 mt-2 border outline-none focus:outline-none focus:ring" multiple>
                  <option value='0' className="border-b text-dark font-light text-xs px-4 py-2">$0</option>
                    <option value='50000' className="border-b text-dark font-light text-xs px-4 py-2">$50k</option>
                    <option value='100000' className="border-b text-dark font-light text-xs px-4 py-2">$100k</option>
                    <option value='150000' className="border-b text-dark font-light text-xs px-4 py-2">$150k</option>
                    <option value='200000' className="border-b text-dark font-light text-xs px-4 py-2">$200k</option>
                    <option value='250000' className="border-b text-dark font-light text-xs px-4 py-2">$250k</option>
                    <option value='300000' className="border-b text-dark font-light text-xs px-4 py-2">$300k</option>
                    <option value='350000' className="border-b text-dark font-light text-xs px-4 py-2">$350k</option>
                    <option value='400000' className="border-b text-dark font-light text-xs px-4 py-2">$400k</option>
                    <option value='450000' className="border-b text-dark font-light text-xs px-4 py-2">$450k</option>
                    <option value='500000' className="border-b text-dark font-light text-xs px-4 py-2">$500k</option>
                    <option value='550000' className="border-b text-dark font-light text-xs px-4 py-2">$550k</option>
                    <option value='600000' className="border-b text-dark font-light text-xs px-4 py-2">$600k</option>
                    <option value='660000' className="border-b text-dark font-light text-xs px-4 py-2">$650k</option>
                    <option value='700000' className="border-b text-dark font-light text-xs px-4 py-2">$700k</option>
                    <option value='750000' className="border-b text-dark font-light text-xs px-4 py-2">$750k</option>
                    <option value='800000' className="border-b text-dark font-light text-xs px-4 py-2">$800k</option>
                    <option value='850000' className="border-b text-dark font-light text-xs px-4 py-2">$850k</option>
                    <option value='900000' className="border-b text-dark font-light text-xs px-4 py-2">$900k</option>
                    <option value='950000' className="border-b text-dark font-light text-xs px-4 py-2">$950k</option>
                    <option value='1000000' className="border-b text-dark font-light text-xs px-4 py-2">$1m</option>
                    <option value='Any' className="border-b text-dark font-light text-xs px-4 py-2">$1m+</option>
                  </select>
                </div>
              </div>              
              <div className="flex items-center justify-between">
                <div/>
                <button onClick={handleClickApply} className="bg-white text-primary font-light text-lg px-2 py-2 outline-none focus:outline-none" type="button">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPriceRange;