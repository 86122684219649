import React from "react";
import { useHistory, Link } from "react-router-dom";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { auth, db } from "../firebase";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const options = ["Finding a home", "Selling my home"];

const SignupPage = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState("#378ABA");

  const [isActive, setActive] = React.useState(false);
  const [selItem, setSelItem] = React.useState("Finding a home");
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({});
  const [zipCode, setZipCode] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let email = e.target.elements.email?.value;
    let password = e.target.elements.password?.value;
    let firstname = e.target.elements.firstname?.value;
    let lastname = e.target.elements.lastname?.value;
    let phone = e.target.elements.phone?.value;

    console.log(email, password, firstname, lastname, phone, address, selItem);
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      setError(null);

      const msg = {
        personalizations: [
          {
            to: [
              {
                email: "forms@smartpropertymktg.com",
                name: "forms@smartpropertymktg.com",
              },
            ],
          },
        ],
        from: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        reply_to: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        subject: `New Seller Profile - ${firstname} ${lastname} `,
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
            <html>
              <body>
                <p>Account Type: ${selItem}</p>
                <p>Name: ${firstname} ${lastname}</p>
                <p>Email: ${email}</p>
                <p>Phone: ${phone}</p>
                <p>Property Address: ${address}</p>
                <p>User ID: ${user.uid}</p>
              </body>
            </html>
          `,
          },
        ],
      };

      const emailRes = await axios.post(
        "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("signup email response=>", emailRes);

      // const contactRes = await axios
      //   .post(
      //     "https://us-central1-smartmktg.cloudfunctions.net/saveContact",
      //     {
      //       contact: {
      //         email,
      //         firstName: firstname,
      //         lastName: lastname,
      //         phone,
      //         type: selItem,
      //         fieldValues:[
      //           {
      //             field: "1",
      //             value: address,
      //           },
      //           {
      //             field: "5",
      //             value: zipCode,
      //           }
      //         ]
      //       }
      //     },
      //     { headers: {
      //       "Content-Type": "application/json",
      //     } },
      //   );

      // console.log("save contact response=>", contactRes);

      if (selItem === "Finding a home") {
        await db.collection("users").add({
          uid: user.uid,
          type: "Finding a home",
          email,
          firstname,
          lastname,
          phone,
          address: address,
          // campaignId: contactRes.data.contactTag.contact,
        });
        setLoading(false);
        history.push("/");
      } else {
        await db.collection("users").add({
          uid: user.uid,
          type: "Selling my home",
          email,
          firstname,
          lastname,
          phone,
          address: address,
          // campaignId: contactRes.data.contactTag.contact,
        });
        let ref = db.collection("homes").doc();
        await db.collection("homes").add({
          address: address,
          coordinates: coordinates,
          homeid: ref.id,
          sellerId: user.uid,
        });
        setLoading(false);
        history.push({
          pathname: `/profile/${ref.id}`,
          state: "",
        });
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  const onOptionSelect = (option) => {
    setSelItem(option);
    setActive(false);
  };

  const callback = (value, location, zipCode, details) => {
    console.log(
      "autocomplete return value=>",
      value,
      location,
      zipCode,
      details
    );
    setAddress(value);
    setCoordinates({
      _lat: location.lat,
      _long: location.lng,
    });
    setZipCode(zipCode);
  };

  return (
    <div className="h-screen flex bg-background">
      <div className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-lg py-4 px-8 relative">
        <Link to="/" className="absolute top-8 right-8">
          <img className="w-4" alt="close" src="assets/icons/close.png" />
        </Link>
        <h1 className="text-2xl font-medium text-primary mt-4 mb-2">
          Create an account
        </h1>
        <p className="mb-8">
          <Link to="/login" className="text-base font-light">
            <span className="text-dark opacity-40 mr-1">
              Already have an account
            </span>
            <span className="text-primary ">Sign in</span>
          </Link>
        </p>

        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}

        <PuffLoader
          color={"#378ABA"}
          loading={loading}
          css={override}
          size={50}
        />

        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <button
              type="button"
              className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11"
              onClick={() => setActive(!isActive)}
            >
              <span className="pl-2">{selItem}</span>
              <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                <i className="fa-caret-down fa"></i>
              </span>
            </button>
            <div
              className={
                (isActive ? "block " : "hidden ") +
                "origin-top-right absolute mt-1 rounded-md shadow-lg z-20 bg-white"
              }
              style={{ width: "86%" }}
            >
              {options.map((option) => (
                <div
                  key={option}
                  className="pl-2 py-2 cursor-pointer"
                  onClick={(e) => onOptionSelect(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label htmlFor="firstname" className="block text-dark text-base">
                First Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="firstname"
                placeholder="First name"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label htmlFor="lastname" className="block text-dark text-base">
                Last Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="lastname"
                placeholder="Last name"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="text-dark text-base">
              Email
            </label>
            <input
              type="email"
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id="email"
              placeholder="Enter email here"
            />
          </div>
          <div className="mt-2">
            <label htmlFor="password" className="text-dark text-base">
              Password
            </label>
            <input
              type="password"
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id="password"
              placeholder="Enter password here"
            />
          </div>
          <div className="mt-2">
            <label htmlFor="phone" className="text-dark text-base">
              Phone # (Optional)
            </label>
            <input
              type="text"
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id="phone"
              placeholder="Enter Ph #"
            />
          </div>
          <div
            className={
              (selItem === "Selling my home" ? "block " : "hidden ") + "mt-2"
            }
          >
            <PlacesAutocomplete parentCallback={callback} />
          </div>

          <div className="mt-2">
            By creating an account, I agree to the{" "}
            <Link to="/terms" className="text-primary underline">
              Terms & Conditions.
            </Link>
          </div>

          <div className="flex mt-6">
            <button
              type="submit"
              className={`bg-primary py-2 px-4 text-base text-white rounded border border-primary w-24`}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
