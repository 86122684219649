import React, { useEffect, useContext } from "react";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import { auth, db } from "../firebase";
import { AuthContext } from "../providers/Authentication";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Geocode from "react-geocode";
import { getGeocode, getZipCode } from "use-places-autocomplete";
import GeneratePDF from "./GeneratePDF";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const options = [
  // "How can we help you?",
  "I would like more information",
  "I would like to see this home",
  "I would like to make an offer",
];

const options_signup = ["Finding a home", "Selling my home"];

const Tab = ({ parentCallback, home }) => {
  const [openTab, setOpenTab] = React.useState(2);
  const [isActive, setActive] = React.useState(false);
  const [selOption, setSelOption] = React.useState("How can we help you?");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [homeId, setHomeId] = React.useState("");
  const [sellerName, setSellerName] = React.useState("");
  const [sellerFirstName, setSellerFirstName] = React.useState("");
  const [sellerLastName, setSellerLastName] = React.useState("");
  const [sellerPhone, setSellerPhone] = React.useState("");
  const [sellerEmail, setSellerEmail] = React.useState("");
  const { currentUser } = useContext(AuthContext);

  const [isActiveSignUp, setActiveSignUp] = React.useState(false);
  const [selItem, setSelItem] = React.useState("Finding a home");
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({});
  const [zipCode, setZipCode] = React.useState("");

  const [reqFirstName, setReqFirstName] = React.useState("");
  const [reqLastName, setReqLastName] = React.useState("");
  const [reqPhone, setReqPhone] = React.useState("");
  const [reqEmail, setReqEmail] = React.useState("");

  const [price, setPrice] = React.useState(null);
  const [closingDate, setClosingDate] = React.useState(null);

  const [propertyAddress, setPropertyAddress] = React.useState("");
  const [propertyZipCode, setPropertyZipCode] = React.useState("");
  const [propertyCity, setPropertyCity] = React.useState("");
  const [propertyState, setPropertyState] = React.useState("");

  const getSellerUserInfo = async (home) => {
    if (!!home.sellerId) {
      const query = await db
        .collection("users")
        .where("uid", "==", home.sellerId)
        .get();
      if (query.docs.length > 0) {
        const data = query.docs[0].data();
        setSellerEmail(data.email);
        setSellerName(data.firstname + " " + data.lastname);
        setSellerFirstName(data.firstname);
        setSellerLastName(data.lastname);
        setSellerPhone(data.phone);
      }
    }
  };

  const getCurrentUserInfo = async () => {
    if (currentUser) {
      let user = await db
        .collection("users")
        .where("uid", "==", currentUser.UserUid)
        .get();
      const data = user.docs[0].data();
      setReqFirstName(data.firstname);
      setReqLastName(data.lastname);
      setReqPhone(data.phone);
      setReqEmail(data.email);
    } else {
    }
  };

  const getPropertyInfo = async (home) => {
    setPropertyAddress(home.address);
    const results = await getGeocode({ address: home.address });
    if (results) {
      const zipCode = await getZipCode(results[0], false);
      setPropertyZipCode(zipCode);
    }
    Geocode.setApiKey("AIzaSyBKhJQEsBURouPrjQ7EsXBNWZ9YT_z46U0");
    Geocode.fromLatLng(home.coordinates._lat, home.coordinates._long).then(
      (response) => {
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setPropertyCity(city);
        setPropertyState(state);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setHomeId(home.homeid);
    getSellerUserInfo(home);
    getCurrentUserInfo();
    getPropertyInfo(home);
  }, [home]);

  const onOptionSelect = (option) => {
    setSelOption(option);
    setActive(false);
  };

  const onChangePhone = (e) => {
    setReqPhone(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let email = e.target.elements.email?.value;
    let name = e.target.elements.name?.value;
    let phone = e.target.elements.phone?.value;
    let message = e.target.elements.message?.value;
    let option = selOption;
    let formatedClosingDate = closingDate?.toDateString();

    let user = await db
      .collection("users")
      .where("uid", "==", currentUser.UserUid)
      .get();
    let userDocId = user.docs[0].id;
    const data = user.docs[0].data();

    const campaignId = data.campaignId;
    const requestHomes = data.requestHomes || [];

    let home = await db.collection("homes").where("homeid", "==", homeId).get();
    const homeData = home.docs[0].data();
    const dealId = homeData.dealId;

    const pdfData = {
      reqFirstName,
      reqLastName,
      reqEmail,
      reqPhone,
      price,
      sellerFirstName,
      sellerLastName,
      sellerEmail,
      sellerPhone,
      propertyAddress,
      propertyZipCode,
      propertyCity,
      propertyState,
      formatedClosingDate,
    };

    try {
      if (option !== "How can we help you?") {
        setError(null);

        let msg = {};
        if (option === "I would like to make an offer") {
          if (!!phone && !!price && !!closingDate) {
            if (!isNaN(phone)) {
              if (phone.length === 10) {
                msg = {
                  personalizations: [
                    {
                      to: [
                        {
                          email: "offers@smartpropertymktg.com",
                          name: "offers@smartpropertymktg.com",
                        },
                      ],
                    },
                  ],
                  from: {
                    email: "forms@smartpropertymktg.com",
                    name: "offers@smartpropertymktg.com",
                  },
                  reply_to: {
                    email: "forms@smartpropertymktg.com",
                    name: "offers@smartpropertymktg.com",
                  },
                  subject: `New Buyer Request - ${name} Property: ${homeId}`,
                  content: [
                    {
                      type: "text/html",
                      value: `<!doctype html>
                      <html>
                        <body>
                          <p>Request: ${option}</p>
                          <p>Name: ${name}</p>
                          <p>Email: ${email}</p>
                          <p>Phone Number: ${phone}</p>
                          <p>Offer Price: $${price}</p>
                          <p>Closing Date: ${formatedClosingDate}</p>
                          <p>Property ID: ${homeId}</p>
                          <p>Link to Profile: https://smartmktg.netlify.app/profile/${homeId}</p>
                          <p>Seller Name: ${sellerName}</p>
                          <p>Seller Email: ${sellerEmail}</p>
                        </body>
                      </html>
                    `,
                    },
                  ],
                };

                GeneratePDF(pdfData, campaignId, dealId);
              } else {
                setLoading(false);
                setError("The length of phone number must be 10 digits.");
                parentCallback(false);
                return;
              }
            } else {
              setLoading(false);
              setError("Please input valid phone number");
              parentCallback(false);
              return;
            }
          } else {
            setLoading(false);
            setError("Please input Offer Price and Closing Date");
            parentCallback(false);
            return;
          }
        } else {
          if (!!message) {
            msg = {
              personalizations: [
                {
                  to: [
                    {
                      email: "forms@smartpropertymktg.com",
                      name: "forms@smartpropertymktg.com",
                    },
                  ],
                },
              ],
              from: {
                email: "forms@smartpropertymktg.com",
                name: "forms@smartpropertymktg.com",
              },
              reply_to: {
                email: "forms@smartpropertymktg.com",
                name: "forms@smartpropertymktg.com",
              },
              subject: `New Buyer Request - ${name} Property: ${homeId}`,
              content: [
                {
                  type: "text/html",
                  value: `<!doctype html>
                  <html>
                    <body>
                      <p>Request: ${option}</p>
                      <p>Name: ${name}</p>
                      <p>Email: ${email}</p>
                      <p>Phone Number: ${phone}</p>
                      <p>Message: ${message}</p>
                      <p>Property ID: ${homeId}</p>
                      <p>Link to Profile: https://smartmktg.netlify.app/profile/${homeId}</p>
                      <p>Seller Name: ${sellerName}</p>
                      <p>Seller Email: ${sellerEmail}</p>
                    </body>
                  </html>
                `,
                },
              ],
            };
          } else {
            setLoading(false);
            setError("Please input message");
            parentCallback(false);
            return;
          }
        }

        await axios.post(
          "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        );

        // const createSecContactRes = await axios
        // .post(
        //   "https://us-central1-smartmktg.cloudfunctions.net/createSecondaryContact",
        //   // "http://localhost:5000/smartmktg/us-central1/createSecondaryContact",
        //   {
        //     contactDeal: {
        //       deal: dealId,
        //       contact: campaignId
        //     }
        //   },
        //   { headers: {
        //     "Content-Type": "application/json",
        //   } },
        // );

        // if(createSecContactRes.status === 200) {
        let updatedRequestHomes = [...requestHomes, homeId];
        await db.collection("users").doc(userDocId).update({
          requestHomes: updatedRequestHomes,
        });
        setLoading(false);
        parentCallback(true);
        // } else {
        //   setLoading(false);
        //   setError("Request Info Error!")
        //   parentCallback(false)
        // }
      } else {
        setLoading(false);
        setError("Please select request info type!");
        parentCallback(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      parentCallback(false);
    }
  };

  const onOptionSelectSignUp = (option) => {
    setSelItem(option);
    setActiveSignUp(false);
  };

  const callback_signup = (value, location, zipCode, details) => {
    setAddress(value);
    setCoordinates({
      _lat: location.lat,
      _long: location.lng,
    });
    setZipCode(zipCode);
  };

  const handleFormSubmitSignUp = async (e) => {
    e.preventDefault();

    setLoading(true);

    let email = e.target.elements.email?.value;
    let password = e.target.elements.password?.value;
    let firstname = e.target.elements.firstname?.value;
    let lastname = e.target.elements.lastname?.value;
    let phone = e.target.elements.phone?.value;

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      setError(null);

      const msg = {
        personalizations: [
          {
            to: [
              {
                email: "forms@smartpropertymktg.com",
                name: "forms@smartpropertymktg.com",
              },
            ],
          },
        ],
        from: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        reply_to: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        subject: `New User Profile - ${firstname} ${lastname} `,
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
            <html>
              <body>
                <p>Account Type: ${selItem}</p>
                <p>Name: ${firstname} ${lastname}</p>
                <p>Email: ${email}</p>
                <p>Phone: ${phone}</p>
                <p>Property Address: ${address}</p>
                <p>User ID: ${user.uid}</p>
              </body>
            </html>
          `,
          },
        ],
      };

      const emailRes = await axios.post(
        "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      );

      // const contactRes = await axios
      //   .post(
      //     "https://us-central1-smartmktg.cloudfunctions.net/saveContact",
      //     {
      //       contact: {
      //         email,
      //         firstName: firstname,
      //         lastName: lastname,
      //         phone,
      //         type: selItem,
      //         fieldValues:[
      //           {
      //             field: "1",
      //             value: address,
      //           },
      //           {
      //             field: "5",
      //             value: zipCode,
      //           }
      //         ]
      //       }
      //     },
      //     { headers: {
      //       "Content-Type": "application/json",
      //     } },
      //   );

      if (selItem === "Finding a home") {
        await db.collection("users").add({
          uid: user.uid,
          type: "Finding a home",
          email,
          firstname,
          lastname,
          phone,
          address: address,
          // campaignId: contactRes.data.contactTag.contact,
        });
        setLoading(false);
      } else {
        await db.collection("users").add({
          uid: user.uid,
          type: "Selling my home",
          email,
          firstname,
          lastname,
          phone,
          address: address,
          // campaignId: contactRes.data.contactTag.contact,
        });
        let ref = db.collection("homes").doc();
        await db.collection("homes").add({
          address: address,
          coordinates: coordinates,
          homeid: ref.id,
          sellerId: user.uid,
        });
        setLoading(false);
      }
      setReqFirstName(firstname);
      setReqLastName(lastname);
      setReqPhone(phone);
      setReqEmail(email);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <>
      <div className="flex flex-wrap shadow-lg rounded">
        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}
        <PuffLoader
          color={"#378ABA"}
          loading={loading}
          css={override}
          size={50}
        />
        {currentUser ? (
          <div className="w-full">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              {/* <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xl font-semibold px-5 py-3 block leading-normal " +
                  (openTab === 1 ? "text-primary" : "text-dark opacity-40")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Schedule a tour
              </a>
            </li> */}
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xl font-semibold px-5 py-3 block leading-normal " +
                    (openTab === 2 ? "text-primary" : "text-dark opacity-40")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Request Info
                </a>
              </li>
            </ul>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
              <div className="px-4 flex-auto min-h-23">
                <div className="tab-content tab-space">
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  ></div>
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <form onSubmit={handleFormSubmit}>
                      <div className="my-2">
                        <button
                          type="button"
                          className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11"
                          onClick={() => setActive(!isActive)}
                        >
                          <span
                            className={
                              (selOption === "How can we help you?"
                                ? "text-gray "
                                : "text-dark ") + "pl-2"
                            }
                          >
                            {selOption}
                          </span>
                          <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                            <i className="fa-caret-down fa"></i>
                          </span>
                        </button>
                        <div
                          className={
                            (isActive ? "block " : "hidden ") +
                            "origin-top-right absolute mt-1 rounded-md shadow-lg z-20 bg-white w-11/12 md:w-5/6 xl:w-tabdropxl "
                          }
                        >
                          {options.map((option) => (
                            <div
                              key={option}
                              className="pl-2 py-2 cursor-pointer"
                              onClick={(e) => onOptionSelect(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="my-2">
                        <input
                          value={`${reqFirstName} ${reqLastName}`}
                          className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                          type="text"
                          id="name"
                          placeholder="Name"
                          readonly="readonly"
                        />
                      </div>
                      <div className="my-2">
                        <input
                          value={reqPhone}
                          className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                          type="text"
                          maxLength={10}
                          id="phone"
                          placeholder="Phone"
                          onChange={onChangePhone}
                        />
                      </div>
                      <div className="my-2">
                        <input
                          value={reqEmail}
                          className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                          type="email"
                          id="email"
                          placeholder="Email"
                          readonly="readonly"
                        />
                      </div>
                      {selOption === "I would like to make an offer" ? (
                        <>
                          <div className="my-2">
                            <NumberFormat
                              thousandSeparator={true}
                              value={price}
                              prefix={"$"}
                              placeholder="Offer Price"
                              className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                              onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                setPrice(value);
                              }}
                            />
                          </div>
                          <div className="my-2">
                            <DatePicker
                              selected={closingDate}
                              onChange={(date) => setClosingDate(date)}
                              minDate={new Date()}
                              // maxDate={addDays(new Date(), 30)}
                              placeholderText="Closing Date"
                              className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="my-2">
                          <textarea
                            className="w-full border border-primary rounded p-2 outline-none focus:outline-none focus:ring"
                            id="message"
                            rows={5}
                            cols={5}
                            placeholder="Message"
                          />
                        </div>
                      )}
                      {/* <div className="my-2">
                      <label className="flex items-center w-full p-2">
                        <input type="checkbox" className="form-checkbox"/>
                        <span className="text-dark font-light ml-2 w-full">I would like to scheduled a tour</span>
                      </label>
                    </div> */}

                      <div className="my-2">
                        <button
                          className="w-full border border-primary bg-primary text-white rounded p-2"
                          type="submit"
                          disabled={sellerEmail === reqEmail}
                        >
                          Request Info
                        </button>
                      </div>
                      <p className="my-2">
                        <a
                          href="/buy-a-home"
                          className="underline text-primary"
                        >
                          Click here
                        </a>{" "}
                        to learn more about the SMART buying process.
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="p-4">
              <h1 className="text-xl font-bold text-primary mt-4 mb-2">
                Interested in this Property?
              </h1>
              <p className="mb-8">
                <span className="text-dark opacity-50 mr-1">
                  Create Your Free Account Today
                </span>
              </p>
              <form onSubmit={handleFormSubmitSignUp}>
                <div className="mb-4">
                  <button
                    type="button"
                    className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11"
                    onClick={() => setActiveSignUp(!isActiveSignUp)}
                  >
                    <span className="pl-2">{selItem}</span>
                    <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                      <i className="fa-caret-down fa"></i>
                    </span>
                  </button>
                  <div
                    className={
                      (isActiveSignUp ? "block " : "hidden ") +
                      "origin-top-right absolute mt-1 rounded-md shadow-lg z-20 bg-white"
                    }
                    style={{ width: "18%" }}
                  >
                    {options_signup.map((option) => (
                      <div
                        key={option}
                        className="pl-2 py-2 cursor-pointer"
                        onClick={(e) => onOptionSelectSignUp(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="w-1/2 pr-2">
                    <label
                      htmlFor="firstname"
                      className="block text-dark text-base"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                      id="firstname"
                      placeholder="First name"
                    />
                  </div>
                  <div className="w-1/2 pl-2">
                    <label
                      htmlFor="lastname"
                      className="block text-dark text-base"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                      id="lastname"
                      placeholder="Last name"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="text-dark text-base">
                    Email
                  </label>
                  <input
                    type="email"
                    className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                    id="email"
                    placeholder="Enter email here"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="password" className="text-dark text-base">
                    Password
                  </label>
                  <input
                    type="password"
                    className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                    id="password"
                    placeholder="Enter password here"
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="phone" className="text-dark text-base">
                    Phone # (Optional)
                  </label>
                  <input
                    type="text"
                    className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                    id="phone"
                    placeholder="Enter Ph #"
                  />
                </div>
                <div
                  className={
                    (selItem === "Selling my home" ? "block " : "hidden ") +
                    "mt-2"
                  }
                >
                  <PlacesAutocomplete parentCallback={callback_signup} />
                </div>

                <div className="mt-2">
                  By creating an account, I agree to the{" "}
                  <Link to="/terms" className="text-primary underline">
                    Terms & Conditions.
                  </Link>
                </div>

                <div className="flex mt-6">
                  <button
                    type="submit"
                    className={`bg-primary py-2 px-4 text-base text-white rounded border border-primary w-24`}
                  >
                    Sign Up
                  </button>
                </div>
                <p className="mb-8">
                  <Link to="/login" className="text-base font-light">
                    <span className="text-dark opacity-40 mr-1">
                      Already have an account?
                    </span>
                    <span className="text-primary ">Sign in</span>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Tab;
