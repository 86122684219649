import React, { useRef, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import axios from "axios";
import { db } from "../firebase";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const optionsAdmin = [
  "Creating Listing",
  "Submit for Approval",
  "Active",
  "Under Contract",
  "Sold",
  "Removed",
];

const optionsSeller = ["Creating Listing", "Submit for Approval"];

const optionsView = [
  "Creating Listing",
  "Submit for Approval",
  "Active",
  "Under Contract",
  "Sold",
  "Removed",
];

const HomeStatus = ({ home, userType, own, onSave }) => {
  const modalStatusRef = useRef(null);
  const [showStatus, setShowStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState("Creating Listing");
  const [isActive, setActive] = React.useState(false);

  const [isActiveView, setActiveView] = React.useState(false);

  const [sellerName, setSellerName] = React.useState("");
  const [sellerFirstName, setSellerFirstName] = React.useState("");
  const [sellerLastName, setSellerLastName] = React.useState("");
  const [sellerPhone, setSellerPhone] = React.useState("");
  const [sellerEmail, setSellerEmail] = React.useState("");

  useEffect(() => {
    setStatus(home.status);
    getSellerUserInfo(home);
  }, [home]);

  const getSellerUserInfo = async (home) => {
    if (!!home.sellerId) {
      const query = await db
        .collection("users")
        .where("uid", "==", home.sellerId)
        .get();
      if (query.docs.length > 0) {
        const data = query.docs[0].data();
        setSellerEmail(data.email);
        setSellerName(data.firstname + " " + data.lastname);
        setSellerFirstName(data.firstname);
        setSellerLastName(data.lastname);
        setSellerPhone(data.phone);
      }
    }
  };

  const showStatusModal = () => {
    setShowStatus(true);
  };

  const hideStatusModal = () => {
    setShowStatus(false);
    setLoading(false);
  };

  const onOptionSelect = (option) => {
    setStatus(option);
    setActive(false);
  };

  const handleSaveStatusClick = async () => {
    let tempHomeData = {
      ...home,
      status: status,
    };

    onSave(tempHomeData);

    setShowStatus(false);

    if (status === "Submit for Approval") {
      let msg = {
        personalizations: [
          {
            to: [
              {
                email: "forms@smartpropertymktg.com",
                name: "forms@smartpropertymktg.com",
              },
            ],
          },
        ],
        from: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        reply_to: {
          email: "forms@smartpropertymktg.com",
          name: "forms@smartpropertymktg.com",
        },
        subject: `Property Approval Requested`,
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
            <html>
              <body>
                <p>A seller has submitted their property for approval by the SMART team.</p>
                <br/>
                <p>Seller: ${sellerFirstName} ${sellerLastName}</p>
                <p>Email: ${sellerEmail}</p>
                <p>Property ID: ${home.homeid}</p>
                <p>Link to Property: https://smartmktg.netlify.app/profile/${home.homeid}</p>
              </body>
            </html>
          `,
          },
        ],
      };
      await axios.post(
        "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
        msg,
        { headers: { "Content-Type": "application/json" } }
      );
    }
  };

  const onOptionViewSelect = (option) => {
    setActiveView(false);
  };

  return (
    <div className="shadow-lg rounded pb-3 mb-1">
      <div className="relative w-full text-xl font-semibold px-5 py-3 block leading-normal text-primary">
        <span className="text-xl font-bold">Property Status</span>
        {userType === "admin" && own && (
          <div
            onClick={() => showStatusModal()}
            className="absolute flex right-4 top-4 cursor-pointer"
          >
            <img className="w-5" alt="edit" src="../assets/icons/edit.png" />
          </div>
        )}
      </div>
      <div className="px-4 my-2">
        <div
          type="button"
          className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11 pl-2"
        >
          {!!status ? status : "Creating Listing"}
        </div>
      </div>
      <Modal
        ref={modalStatusRef}
        open={showStatus}
        center
        initialFocusRef={modalStatusRef}
        onClose={hideStatusModal}
        animationDuration={400}
      >
        <PuffLoader
          color={"#378ABA"}
          loading={loading}
          css={override}
          size={50}
        />
        <div className="text-center my-5">
          <h1 className="text-dark text-2xl font-semibold">
            Edit Your Home Status
          </h1>
        </div>
        <form action="">
          <div className="w-full text-base">
            <button
              type="button"
              className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11"
              onClick={() => setActive(!isActive)}
            >
              <span className="pl-2">
                {!!status ? status : "Creating Listing"}
              </span>
              <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                <i className="fa-caret-down fa"></i>
              </span>
            </button>
            {userType === "admin" && own && (
              <div
                className={
                  (isActive ? "block " : "hidden ") +
                  "origin-top-right mt-1 rounded-md shadow-lg z-20 bg-white"
                }
              >
                {optionsAdmin.map((option) => (
                  <div
                    key={option}
                    className="pl-2 py-2 cursor-pointer border-b border-solid"
                    onClick={(e) => onOptionSelect(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
            {own && (
              <div
                className={
                  (isActive ? "block " : "hidden ") +
                  "origin-top-right mt-1 rounded-md shadow-lg z-20 bg-white"
                }
              >
                {optionsSeller.map((option) => (
                  <div
                    key={option}
                    className="pl-2 py-2 cursor-pointer border-b border-solid"
                    onClick={(e) => onOptionSelect(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="text-center mt-5">
            <input
              type="button"
              value="Save"
              onClick={handleSaveStatusClick}
              className="w-1/2 bg-dark text-white font-light text-lg px-2 py-2 outline-none cursor-pointer focus:outline-none"
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default HomeStatus;
