import React, { useEffect, useState } from "react";
import Property from "./Property";

const HomeProperties = ({ home }) => {
  const [homeData, setHomeData] = useState(home);
  const [propertyList, setPropertyList] = useState([
    {
      icon: "../assets/icons/built.png",
      name: "Year Built",
      value: "",
    },
    {
      icon: "../assets/icons/floor.png",
      name: "Stories / Floors",
      value: "",
    },
    {
      icon: "../assets/icons/swimming.png",
      name: "Pool",
      value: "No",
    },
    {
      icon: "../assets/icons/calculator.png",
      name: "HOA",
      value: "No HOA",
    },
    {
      icon: "../assets/icons/heating.png",
      name: "Heating",
      value: "No",
    },
    {
      icon: "../assets/icons/fan.png",
      name: "Cooling",
      value: "No",
    },
  ]);

  useEffect(() => {
    setHomeData(home);
  }, [home]);

  useEffect(() => {
    setPropertyList([
      {
        icon: "../assets/icons/built.png",
        name: "Year Built",
        value: homeData.yearBuilt,
      },
      {
        icon: "../assets/icons/floor.png",
        name: "Stories / Floors",
        value: homeData.floors,
      },
      {
        icon: "../assets/icons/swimming.png",
        name: "Pool",
        value: "No",
      },
      {
        icon: "../assets/icons/calculator.png",
        name: "HOA",
        value: !!homeData.hoa ? homeData.hoafee : "No HOA",
      },
      {
        icon: "../assets/icons/heating.png",
        name: "Heating",
        value: homeData.heating,
      },
      {
        icon: "../assets/icons/fan.png",
        name: "Cooling",
        value: homeData.cooling,
      },
    ]);
  }, [homeData]);

  return (
    <div className="block mx-auto py-2 mt-2 text-center">
      {propertyList.map((item, i) => (
        <div key={i} className="inline-block mb-6 w-1/2 md:w-1/3">
          <Property inform={item} />
        </div>
      ))}
    </div>
  );
};
export default HomeProperties;
