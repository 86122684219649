import React from "react";

const emptyImage = ["assets/images/empty.png"];

const CarouselItem = ({ images }) => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);

  const tempImages = !!images && images.length !== 0 ? images : emptyImage;
  const refs = tempImages.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = (i) => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const sliderToImage = (i, e) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const totalImages = tempImages.length;

  const nextImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  const arrowStyle =
    "absolute text-black text-sm z-10 bg-white h-8 w-8 rounded-full flex items-center justify-center";

  const sliderControl = (isLeft) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
      style={{ top: "40%" }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
        <i
          className={(isLeft ? "fa-chevron-left " : "fa-chevron-right ") + "fa"}
        ></i>
      </span>
    </button>
  );

  const dot =
    "cursor-pointer h-2 w-2 mx-1 bg-white hover:bg-primary rounded-full inline-block duration-700 ease-in";

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <div 
      className="flex justify-center w-full items-center"
      style={{
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem' 
      }}
    >
      <div className="relative w-full">
        <div className="inline-flex overflow-x-hidden ">
          {sliderControl(true)}
          {tempImages.map((img, i) => (
            <div className="w-full flex-shrink-0" key={img} ref={refs[i]}>
              {loaded ? null : (
                <div
                  style={{
                    background: "#E5E5E5",
                    // height: "252px",
                    // width: "373px",
                  }}
                />
              )}
              <img
                src={img}
                onLoad={handleImageLoaded}
                alt={i}
                className="w-full h-72 object-cover"
                style={{
                  borderTopLeftRadius: '0.5rem',
                  borderTopRightRadius: '0.5rem' 
                }}
              />
            </div>
          ))}
          {sliderControl()}
        </div>
        <div className="absolute bottom-4 flex w-full justify-center">
          {tempImages.map((img, i) => (
            <span
              key={img}
              className={`${dot} ${currentImage === i ? "bg-primary" : ""}`}
              onClick={(e) => sliderToImage(i, e)}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
