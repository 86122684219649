import React, { useState, useRef, useContext } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { auth, db } from "../firebase";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../providers/Authentication";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const options = ["Yes", "No"];

const BuyAHomePage = () => {
  const history = useHistory();
  const signUpRef = useRef(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isActive, setActive] = React.useState(false);
  const [selItem, setSelItem] = React.useState("Yes");
  const { currentUser } = useContext(AuthContext);

  const onOptionSelect = (option) => {
    setSelItem(option);
    setActive(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (currentUser) {
      confirmAlert({
        title: "Alert",
        message: "You are already signed in.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    } else {
      setLoading(true);

      let email = e.target.elements.email?.value;
      let password = e.target.elements.password?.value;
      let firstname = e.target.elements.firstname?.value;
      let lastname = e.target.elements.lastname?.value;
      let phone = e.target.elements.phone?.value;

      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        setError(null);

        const msg = {
          personalizations: [
            {
              to: [
                {
                  email: "forms@smartpropertymktg.com",
                  name: "forms@smartpropertymktg.com",
                },
              ],
            },
          ],
          from: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          reply_to: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          subject: `New Buyer Profile - ${firstname} ${lastname}`,
          content: [
            {
              type: "text/html",
              value: `<!doctype html>
              <html>
                <body>
                  <p>Account Type: Finding a home</p>
                  <p>Name: ${firstname} ${lastname}</p>
                  <p>Email: ${email}</p>
                  <p>Phone: ${phone}</p>
                  <p>User ID: ${user.uid}</p>
                </body>
              </html>
            `,
            },
          ],
        };

        const emailRes = await axios.post(
          "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        );

        // const contactRes = await axios
        //   .post(
        //     "https://us-central1-smartmktg.cloudfunctions.net/saveContact",
        //     {
        //       contact: {
        //         email,
        //         firstName: firstname,
        //         lastName: lastname,
        //         phone,
        //         type: "Finding a home",
        //         fieldValues:[
        //           {
        //             field: "1",
        //             value: "",
        //           },
        //           {
        //             field: "5",
        //             value: "",
        //           }
        //         ]
        //       }
        //     },
        //     { headers: {
        //       "Content-Type": "application/json",
        //     } },
        //   );

        await db.collection("users").add({
          uid: user.uid,
          type: "Finding a home",
          email,
          firstname,
          lastname,
          phone,
          // campaignId: contactRes.data.contactTag.contact,
        });

        setError(null);
        setLoading(false);
        history.push("/");
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  return (
    <div className="w-full">
      <Nav />
      <div className="relative">
        <div className="block bg-buy-header-texture bg-cover bg-no-repeat bg-center w-full text-white text-center text-2xl md:text-4xl lg:text-5xl font-Cocogoose py-16 md:py-32 lg:py-40">
          <div className="mb-1 md:mb-4 lg:mb-8">Interested in a Property?</div>
          <div className="mt-1 md:mt-4 lg:mt-8">
            Become a SMART Buyer Today!
          </div>
        </div>
      </div>

      <div className="p-10 md:px-24 lg:px-32 lg:py-24 text-md md:text-lg lg:text-2xl">
        Thank you for reviewing the properties for sale on SMART Property
        Marketing. You are looking at homes and/or properties that are
        considered{" "}
        <b>
          <i>in-house</i>
        </b>{" "}
        by our company and not listed on any Multiple Listing Service (MLS).
        This gives you a definite advantage on buyers who are shopping for homes
        on the monster real estate empires who share each other’s listings.
        Consider SMART your exclusive glimpse to homes that are not posted in
        the online market services.
      </div>

      <div className="relative block lg:flex lg:flex-row-reverse justify-between mx-auto py-1 lg:py-2 lg:items-center">
        <div className="w-full lg:w-4/5 lg:px-16 lg:h-imgHeight">
          <img
            src="../assets/images/how_it_works.png"
            alt="sell-home1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full lg:w-1/2 p-10 md:px-24 lg:p-4 lg:absolute bg-white lg:border lg:border-black left-16">
          <p className="text-3xl font-bold">How It Works</p>
          <br />
          <div className="text-sm text-dark mt-1">
            Feel free to look at all the properties we represent, however, if
            you want to engage with our SMART Sellers, you’ll need to contact us
            to set up your own&nbsp;
            <span className="text-primary underline">
              SMART Buyers Profile.
            </span>
          </div>
          <br />
          <div className="text-sm text-dark mt-1">
            Within the profile, we will need obvious information like your name,
            phone number and email address. However, we take things a step
            further that benefits you, the Buyer, and our SMART Sellers. Along
            with a very specific questionnaire regarding your buying
            preferences, we will run a personal and banking background check in
            order to pass SMART’s vetting criteria. Already pre-approved? Great!
            Please be prepared to provide that information to us when necessary.
          </div>
          <br />
          <div className="text-sm text-dark mt-1">
            Once you’ve passed our vetting process, you will be able to request
            an introduction to our SMART Sellers. Once you’ve contacted the
            Seller and are ready to make an offer, SMART provides an electronic
            offer/acceptance worksheet for you to complete and send to the
            Seller for review. The Seller can choose to accept your offer or
            provide a counter offer via electronic documentation. Once an offer
            has been accepted and executed by both parties via digital
            signature, the SMART Real Estate Legal Team will be notified and
            will reach out to both parties to handle the transaction through
            closing.
          </div>
        </div>
      </div>

      <div
        ref={signUpRef}
        className="max-w-7xl relative text-center mx-auto mb-16 px-6 md:px-32 lg:px-40"
      >
        <div className="text-black pt-8 md:pt-12 text-2xl md:text-4xl font-bold leading-snug">
          Become a SMART Buyer today! 
        </div>
        <div className="text-black mt-5 mb-6 text-lg md:text-xl italic">
          Enter your details to create your free buyers account.
        </div>
        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}

        <PuffLoader
          color={"#378ABA"}
          loading={loading}
          css={override}
          size={50}
        />

        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="options"
              className="block text-left text-dark text-base"
            >
              Are you already pre-approved with a Lender?
            </label>
            <button
              type="button"
              className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11"
              onClick={() => setActive(!isActive)}
            >
              <span className="pl-2">{selItem}</span>
              <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                <i className="fa-caret-down fa"></i>
              </span>
            </button>
            <div
              className={
                (isActive ? "block " : "hidden ") +
                "origin-top-right mt-1 rounded-md shadow-lg z-20 bg-white"
              }
            >
              {options.map((option) => (
                <div
                  key={option}
                  className="pl-2 py-2 cursor-pointer"
                  onClick={(e) => onOptionSelect(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="firstname"
                className="block text-left text-dark text-base"
              >
                First Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="firstname"
                placeholder="First name"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="lastname"
                className="block text-left text-dark text-base"
              >
                Last Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="lastname"
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="email"
                className="block text-left text-dark text-base"
              >
                Email
              </label>
              <input
                type="email"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="email"
                placeholder="Enter email here"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="password"
                className="block text-left text-dark text-base"
              >
                Password
              </label>
              <input
                type="password"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="password"
                placeholder="Enter password here"
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="phone"
                className="block text-left text-left text-dark text-base"
              >
                Phone # (Optional)
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="phone"
                placeholder="Enter Ph #"
              />
            </div>
            <div className="w-1/2 pl-2 mb-4 text-left">
              {/* <PlacesAutocomplete parentCallback={callback}/> */}
            </div>
          </div>

          <button
            type="submit"
            className={`mt-6 w-72 bg-primary py-2 px-4 text-base text-white rounded border border-primary`}
          >
            <span className="mr-2">CREATE MY FREE ACCOUNT</span>
            <i className="fa-arrow-right fa"></i>
          </button>
        </form>
        <div className="text-center mt-2">
          By creating an account, I agree to the{" "}
          <a href="#" className="text-primary underline">
            Terms & Conditions.
          </a>
        </div>
        <div className="text-center mt-2">
          Already have an account?&nbsp;&nbsp;
          <Link to="/login">
            <span className="text-primary underline">Sign in here</span>
          </Link>
          {/* <a href="#" className="text-primary underline">Sign in here</a> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BuyAHomePage;
