import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { auth } from "../firebase";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const [emailHasBeenSent, setEmailHasBeenSent] = React.useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let email = e.target.elements.email?.value;

    try {
      await auth.sendPasswordResetEmail(email);
      setEmailHasBeenSent(true);
      // setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className='h-screen flex bg-background'>
      <div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-lg py-4 px-8 relative'>
        <Link to="/" className="absolute top-8 right-8">
          <img className="w-4" src="assets/icons/close.png"/>
        </Link>
        <h1 className='text-2xl font-medium text-primary mt-4 mb-2'>
          Forgot your password?
        </h1>
        <p className='mb-5 w-10/12'>
          <span className='text-dark text-sm opacity-40 mr-1'>Please enter the email address registered on your account</span>
        </p>
        {emailHasBeenSent && (
          <div className="p-2 bg-green-400 w-full text-white text-center mb-3">
            If you have an account with this email on file, you will receive an email with more information on resetting your password.
          </div>
        )}
        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor='email' className="text-dark text-base">Email</label>
            <input
              type='email'
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id='email'
              placeholder='Enter email here'
            />
          </div>
          <div className='flex mt-4'>
            <button
              type="submit"
              className={`bg-primary py-2 px-4 text-base text-white rounded border border-primary w-44`}
            >
              Reset password
            </button>
          </div>
          <div className="mt-6">
            <span className='text-dark opacity-40 text-xs'>
              If you have an account with this email on file, you will receive an email with more information on resetting your password
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;