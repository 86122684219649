import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import Nav from '../components/Nav';
import "react-multi-carousel/lib/styles.css";
import { AuthContext } from "../providers/Authentication";
import { db, auth } from "../firebase";
import PlacesAutocomplete from '../components/PlacesAutocomplete';
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import HomeCard from "../components/HomeCard";
import { getPreciseDistance, convertDistance } from "geolib";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#378ABA';
`;

const options = [
  "Finding a home",
  "Selling my home"
]

const ProfilePage = () => {
  const history = useHistory();
  const {currentUser} = useContext(AuthContext)
  const [loading, setLoading] = React.useState(false);

  const [isActive, setActive] = React.useState(false);
  const [selItem, setSelItem] = React.useState("Finding a home");
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [error, setError] = React.useState(null);

  const currentPosition = {
    latitude: 32.92521,
    longitude: -97.14094,
  };

  const [homeInformsTemp, setHomeInformsTemp] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result=>", result);
          let temp = {
            latitude: result.latitude,
            longitude: result.longitude,
          };
          fetchUserHomeInforms(temp);
        },
        (error) => {
          console.log("error=>", error);
          fetchUserHomeInforms(currentPosition);
        }
      );
  }, []);

  const fetchUserHomeInforms = async (temp) => {
    try {
      const user = await db.collection("users")
        .where("uid", "==", currentUser.UserUid)
        .get();
      const userData = await user.docs[0].data()
      setSelItem(userData.type)
      setFirstName(userData.firstname)
      setLastName(userData.lastname)
      setEmail(userData.email)
      setPhone(userData.phone)
      setAddress(userData.address)
      const homeData = [];
      const response = db.collection("homes");
      const totalHomeData = await response.get();
      // eslint-disable-next-line array-callback-return
      totalHomeData.docs.map((item) => {
        if(userData.type === "admin") {
          homeData.push(item.data());
        } else if(item.data().sellerId === currentUser.UserUid) {
          homeData.push(item.data());
        }
      });
      const homeDataDisplay = homeData
        .filter((item) => {
          if (item.squarefeet * 1 >= 1000) {
            return true;
          } else return false;
        })
        .map((item) => {
          const { _lat, _long } = item.coordinates;
          let formattedMiles = 0;

          if (_lat && _long) {
            let distances = getPreciseDistance(
              { latitude: _lat, longitude: _long },
              { latitude: temp.latitude, longitude: temp.longitude }
            );
            let miles = convertDistance(distances, "mi");

            if (miles < 10) {
              formattedMiles = miles.toFixed(1);
            } else {
              formattedMiles = miles.toFixed(0);
            }
          }

          return { ...item, information: formattedMiles };
        });
      homeDataDisplay.sort((a, b) =>
        a.information * 1 > b.information * 1 ? 1 : -1
      );
      setHomeInformsTemp(homeDataDisplay);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let email = e.target.elements.email?.value;
    let firstname = e.target.elements.firstname?.value;
    let lastname = e.target.elements.lastname?.value;
    let phone = e.target.elements.phone?.value;

    try{
      setError(null); 
      let user = await db.collection("users")
        .where("uid", "==", currentUser.UserUid)
        .get();
      let userDocId = user.docs[0].id

      if(address !== '') {
        await db.collection("users")
        .doc(userDocId)
        .update({
          uid: currentUser.UserUid,
          type: selItem,
          email,
          firstname,
          lastname,
          phone,
          address: address
        });
      } else {
        await db.collection("users")
        .doc(userDocId)
        .update({
          uid: currentUser.UserUid,
          type: selItem,
          email,
          firstname,
          lastname,
          phone
        });
      }      

      setLoading(false);
      history.push("/");
    }
    catch(error){
      setLoading(false);
      setError(error.message);
    }
  }

  const onOptionSelect = (option) => {
    setSelItem(option);
    setActive(false);
  }

  const callback = (value) => {
    setAddress(value)
  }

  const handleLogOut = () => {
    confirmAlert({
      title: 'Log Out',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            auth.signOut();
            history.push("/");
          }
        },
        {
          label: 'No',
          onClick: () => {
            
          }
        }
      ]
    });
  }

  const handleClick = (home) => {
    history.push({
      pathname: `/profile/${home.homeid}`,
      state: home.information,
    });
  };

  return (
    <div className="w-full block">
      <Nav/>   
      {error !== null && (
        <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
          {error}
        </div>
      )}

      <PuffLoader color={"#378ABA"} loading={loading} css={override} size={50} />  
      <div className="max-w-7xl block md:flex justify-between mx-auto py-2 mt-1 mb-16">
        <div className="w-full md:w-1/2 px-4">
          <h1 className='text-2xl font-medium text-primary mt-4 ml-6 mb-6'>
            Property Profile
          </h1>
          <div className="block items-center justify-between mx-auto my-2 px-2">
            {homeInformsTemp &&
              homeInformsTemp.map((home, i) => (
                <div
                  key={i}
                  className="w-full inline-block max-w-md"
                  onClick={() => handleClick(home)}
                >
                  <HomeCard home={home} />
                </div>
              ))}
            {homeInformsTemp.length === 0 && 
              <div className="text-xl font-medium text-dark">You don't have a home.</div>
            }
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4">
          <h1 className='text-2xl font-medium text-primary mt-4 mb-6'>
            My Profile
          </h1>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <button type="button" className="w-full flex items-center justify-between text-dark border border-primary rounded-md h-11" onClick={() => setActive(!isActive)}>
                <span className="pl-2">{selItem}</span>
                <span className="z-10 h-full leading-snug font-normal text-center text-base items-center w-10 ml-2 mt-5">
                  <i className="fa-caret-down fa"></i>
                </span>
              </button>
              <div className={(isActive ? 'block ' : 'hidden ') + "origin-top-right absolute mt-1 rounded-md shadow-lg z-20 bg-white"} style={{width: '38rem'}}>
                {options.map((option) => <div key={option} className="pl-2 py-2 cursor-pointer" onClick={(e) => onOptionSelect(option)}>{option}</div>)}
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-1/2 pr-2">
                <label htmlFor='firstname' className="block text-dark text-base">First Name</label>
                <input
                  type='text'
                  className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                  id='firstname'
                  placeholder='First name'
                  defaultValue={firstName}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor='lastname' className="block text-dark text-base">Last Name</label>
                <input
                  type='text'
                  className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                  id='lastname'
                  placeholder='Last name'
                  defaultValue={lastName}
                />
              </div>
            </div>
            <div>
              <label htmlFor='email' className="text-dark text-base">Email</label>
              <input
                type='email'
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id='email'
                placeholder='Enter email here'
                defaultValue={email}
              />
            </div>
            <div className="mt-2">
              <label htmlFor='phone' className="text-dark text-base">Phone # (Optional)</label>
              <input
                type='text'
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id='phone'
                placeholder='Enter Ph #'
                defaultValue={phone}
              />
            </div>
            <div className={(selItem === "Selling my home" || selItem === "admin" ? 'block ' : 'hidden ') + "mt-2"}>
              { address && <PlacesAutocomplete parentCallback={callback} address={address}/> }
            </div>

            <div className='flex mt-10 jsutify-center'>
              <button
                type="submit"
                className={`bg-primary py-2 px-4 text-base text-white rounded border border-primary w-full`}
              >
                Update
              </button>
            </div>
          </form>
          <div className='flex mt-10 jsutify-center'>
            <button onClick={handleLogOut} className={`py-2 px-4 text-base text-primary rounded border border-red w-full`}>
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  ) 
};

export default ProfilePage;
