import React from "react";
import NumberFormat from 'react-number-format';

const Property = ({inform}) => {
  return (
    <div className="p-1">
      <div className="w-full bg-light-blue-01 rounded flex items-center p-2">
        <img className="w-12 h-12 p-2" alt="infom" src={inform.icon}/>
        <div className="ml-4 text-left">
          <div className="text-dark font-light whitespace-nowrap text-sm">{inform.name}</div>
          <div className="text-dark font-medium text-sm">
            {inform.name !== 'HOA' ? inform.value : 
              inform.value === 'No HOA' ? 'No HOA' :
                <NumberFormat
                  value={inform.value}
                  className="foo"
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  suffix="/yr"
                />
            }
          </div>        
        </div>
      </div>
    </div>
  );
}
export default Property;