import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { auth } from "../firebase";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#378ABA';
`;

const LoginPage = () => {
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    let email = e.target.elements.email?.value;
    let password = e.target.elements.password?.value;

    console.log(email, password);
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      console.log("login user=>", user)
      setLoading(false);
      setError(null);
      history.push("/");
    } catch (err) {
      setLoading(false);
      setError(err.message)
    }
  };

  return (
    <div className='h-screen flex bg-background'>
      <div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-lg py-4 px-8 relative'>
        <Link to="/" className="absolute top-8 right-8">
          <img className="w-4" alt="close" src="assets/icons/close.png"/>
        </Link>
        <h1 className='text-2xl font-medium text-primary mt-4 mb-2'>
          Login
        </h1>
        <p className='mb-8'>
          <Link to="/signup" className="text-base font-light">
            <span className='text-dark opacity-40 mr-1'>New here!</span>
            <span className="text-primary ">Sign Up</span>
          </Link>
        </p>

        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}
          
        <PuffLoader color={"#378ABA"} loading={loading} css={override} size={50} />
        
        <form onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor='email' className="text-dark text-base">Email</label>
            <input
              type='email'
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id='email'
              placeholder='Enter email here'
            />
          </div>
          <div className="mt-2">
            <label htmlFor='password' className="text-dark text-base">Password</label>
            <input
              type='password'
              className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
              id='password'
              placeholder='Enter password here'
            />
          </div>
          <div className="text-right">
            <Link to="/forgot" className="text-primary">
              <span className="text-sm">Forgot Password?</span>
            </Link>
          </div>

          <div className='flex mt-6'>
            <button
              type="submit"
              className={`bg-primary py-2 px-4 text-base text-white rounded border border-primary w-24`}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;