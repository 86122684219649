import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import NumberFormat from "react-number-format";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PlacesAutocomplete from "./PlacesAutocomplete";

const HomeDetails = forwardRef(
  ({ home, userType, own, onSave, onMap }, ref) => {
    const modalDetailsRef = useRef(null);
    const [showDetails, setShowDetails] = useState(false);

    const [address, setAddress] = useState("");
    const [price, setPrice] = useState("");
    const [square, setSquare] = useState("");
    const [lot, setLot] = useState("");
    const [bedrooms, setBedrooms] = useState("");
    const [bathrooms, setBathrooms] = useState("");
    const [parking, setParking] = useState("");
    const [fee, setFee] = useState("");
    const [selectedHoa, setSelectedHoa] = useState("no");
    const [yearBuilt, setYearBuilt] = useState("");
    const [floors, setFloors] = useState("");
    const [heating, setHeating] = useState("");
    const [cooling, setCooling] = useState("");

    useEffect(() => {
      setAddress(!!home.address ? home.address : "");
      setPrice(!!home.price ? home.price : "");
      setSquare(!!home.squarefeet ? home.squarefeet : "");
      setLot(!!home.lotsize ? home.lotsize : "");
      setBedrooms(!!home.bedrooms ? home.bedrooms : "");
      setBathrooms(!!home.bathrooms ? home.bathrooms : "");
      setParking(!!home.parking ? home.parking : "");
      setFee(!!home.hoafee ? home.hoafee : "");
      setSelectedHoa(!!home.hoa && home.hoa === true ? "yes" : "no");
      setYearBuilt(!!home.yearBuilt ? home.yearBuilt : "");
      setFloors(!!home.floors ? home.floors : "");
      setHeating(!!home.heating ? home.heating : "");
      setCooling(!!home.cooling ? home.cooling : "");
    }, [home]);

    const goToMap = (home) => {
      onMap(home);
    };

    const showDetailsModal = () => {
      setShowDetails(true);
    };

    useImperativeHandle(ref, () => ({
      showModal() {
        setShowDetails(true);
      },
    }));

    const hideDetailsModal = () => {
      setShowDetails(false);
    };

    const callback = (value) => {
      setAddress(value);
    };

    const onChangePriceHandler = (values) => {
      const { value } = values;
      setPrice(value);
    };

    const onChangeSquareHandler = (e) => {
      setSquare(e.currentTarget.value);
    };

    const onChangeLotHandler = (e) => {
      setLot(e.currentTarget.value);
    };

    const onChangeBedroomsHandler = (e) => {
      setBedrooms(e.currentTarget.value);
    };

    const onChangeBathroomsHandler = (e) => {
      setBathrooms(e.currentTarget.value);
    };

    const onChangeParkingHandler = (e) => {
      setParking(e.currentTarget.value);
    };

    const onChangeFeeHandler = (values) => {
      const { value } = values;
      setFee(value);
    };

    const handleHoaChange = (e) => {
      setSelectedHoa(e.currentTarget.value);
    };

    const onChangeYearBuiltHandler = (e) => {
      setYearBuilt(e.currentTarget.value);
    };

    const onChangeFloorsStoriesHandler = (e) => {
      setFloors(e.currentTarget.value);
    };

    const onChangeHeatingHandler = (e) => {
      setHeating(e.currentTarget.value);
    };

    const onChangeCoolingHandler = (e) => {
      setCooling(e.currentTarget.value);
    };

    const handleSaveDetailsClick = async () => {
      let tempHomeData = {
        ...home,
        address: address,
        bedrooms: bedrooms,
        bathrooms: bathrooms,
        price: price,
        squarefeet: square,
        lotsize: lot,
        parking: parking,
        hoafee: fee,
        hoa: selectedHoa === "yes" ? true : false,
        yearBuilt: yearBuilt,
        floors: floors,
        heating: heating,
        cooling: cooling,
      };

      onSave(tempHomeData);
      setShowDetails(false);
    };

    return (
      <>
        <div className="flex items-center justify-between">
          <span className="text-dark text-3xl font-normal">
            {home.address
              .split(",")[0]
              .trim()
              .replace(/\w+[.!?]?$/, "")}
          </span>
          <span className="text-dark text-3xl font-normal">
            <NumberFormat
              value={home.price}
              className="foo"
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </span>
        </div>
        <div
          className="flex items-center mt-3 cursor-pointer"
          onClick={() => goToMap(home)}
        >
          <img className="w-7" alt="pin2" src="../assets/icons/pin.png" />
          <span className="text-dark text-base font-light opacity-50 ml-2 cursor-pointer">
            {home.address}
          </span>
        </div>
        <div className="relative block mx-auto text-center sm:flex items-center mt-3">
          <div className="inline-block mb-6">
            <div className="flex w-40 sm:w-auto mr-2">
              <img
                className="w-7"
                alt="squares2"
                src="../assets/icons/squares.png"
              />
              <span className="text-dark text-base font-light ml-2">
                {home.squarefeet}&nbsp;Square&nbsp;Feet
              </span>
            </div>
          </div>
          <div className="inline-block mb-6">
            <div className="flex w-40 sm:w-auto mr-2">
              <img
                className="w-7 ml-0 sm:ml-5"
                alt="lotsize"
                src="../assets/icons/pin.png"
              />
              <span className="text-dark text-base font-light ml-2">
                {home.lotsize}&nbsp;Acres
              </span>
            </div>
          </div>
          <div className="inline-block mb-6">
            <div className="flex w-40 sm:w-auto mr-2">
              <img
                className="w-7 ml-0 sm:ml-5"
                alt="bedroom2"
                src="../assets/icons/bedroom.png"
              />
              <span className="text-dark text-base font-light ml-2">
                {home.bedrooms}&nbsp;Bedrooms
              </span>
            </div>
          </div>
          <div className="inline-block mb-6">
            <div className="flex w-40 sm:w-auto mr-2">
              <img
                className="w-7 ml-0 sm:ml-5"
                alt="bathtub2"
                src="../assets/icons/bathtub.png"
              />
              <span className="text-dark text-base font-light ml-2">
                {home.bathrooms}&nbsp;Bathrooms
              </span>
            </div>
          </div>
          <div className="inline-block mb-6">
            <div className="flex w-40 sm:w-auto mr-2">
              <img
                className="w-7 ml-0 sm:ml-5"
                alt="car2"
                src="../assets/icons/car.png"
              />
              <span className="text-dark text-base font-light ml-2">
                {home.parking}&nbsp;Parking&nbsp;Spot(s)
              </span>
            </div>
          </div>
          {userType === "admin" && own && (
            <div
              onClick={() => showDetailsModal()}
              className="absolute flex right-4 -top-3 cursor-pointer"
            >
              <img className="w-5" alt="edit" src="../assets/icons/edit.png" />
            </div>
          )}
        </div>
        <Modal
          ref={modalDetailsRef}
          open={showDetails}
          center
          initialFocusRef={modalDetailsRef}
          onClose={hideDetailsModal}
          animationDuration={400}
        >
          <div className="text-center my-5">
            <h1 className="text-dark text-2xl font-semibold">
              Edit Your Home Details
            </h1>
          </div>
          <form action="">
            <div className="w-full text-base">
              <PlacesAutocomplete
                parentCallback={callback}
                address={home.address}
              />
            </div>
            <div className="w-full text-base mt-2">
              <label htmlFor="price">
                Asking Price
                <NumberFormat
                  thousandSeparator={true}
                  value={price}
                  prefix={"$"}
                  className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                  onValueChange={(values) => onChangePriceHandler(values)}
                />
              </label>
            </div>
            <div className="w-full text-base mt-2 flex items-center">
              <div className="w-1/2 mr-2">
                <label htmlFor="square">
                  Square Footage
                  <input
                    id="square"
                    type="number"
                    min="0"
                    placeholder="Type Square Footage"
                    value={square}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeSquareHandler(e)}
                  />
                </label>
              </div>
              <div className="w-1/2 ml-2">
                <label htmlFor="lot">
                  Lot Size (acres)
                  <input
                    id="lot"
                    type="number"
                    min="0"
                    placeholder="Type Lot Size"
                    value={lot}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeLotHandler(e)}
                  />
                </label>
              </div>
            </div>
            <div className="w-full text-base mt-2 flex items-center">
              <div className="w-1/2 mr-2">
                <label htmlFor="betrooms">
                  Number of Bedrooms
                  <input
                    id="bedrooms"
                    type="number"
                    min="0"
                    placeholder="Type Number of Bedrooms"
                    value={bedrooms}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeBedroomsHandler(e)}
                  />
                </label>
              </div>
              <div className="w-1/2 ml-2">
                <label htmlFor="bathrooms">
                  Number of Bathrooms
                  <input
                    id="bathrooms"
                    type="number"
                    min="0"
                    placeholder="Type Number of Bathrooms"
                    value={bathrooms}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeBathroomsHandler(e)}
                  />
                </label>
              </div>
            </div>
            <div className="w-full text-base mt-2">
              <label htmlFor="parking">
                Garage Parking Spots
                <input
                  id="parking"
                  type="number"
                  min="0"
                  placeholder="Type Number of Parking Spots"
                  value={parking}
                  className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                  onChange={(e) => onChangeParkingHandler(e)}
                />
              </label>
            </div>
            <div className="w-full text-base mt-2 flex items-center">
              <div className="w-1/2 mr-2">
                <p>HOA</p>
                <div className="flex">
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="yes"
                        checked={selectedHoa === "yes"}
                        onChange={(e) => handleHoaChange(e)}
                      />
                      Yes
                    </label>
                  </div>
                  <div className="radio ml-2">
                    <label>
                      <input
                        type="radio"
                        value="no"
                        checked={selectedHoa === "no"}
                        onChange={(e) => handleHoaChange(e)}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-1/2 ml-2">
                <label htmlFor="fee">
                  HOA Annual Fee
                  <NumberFormat
                    thousandSeparator={true}
                    value={fee}
                    prefix={"$"}
                    suffix={"/yr"}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onValueChange={(values) => onChangeFeeHandler(values)}
                    // disabled={selectedHoa === "yes" ? false : true}
                  />
                </label>
              </div>
            </div>
            <div className="w-full text-base mt-2 flex items-center">
              <div className="w-1/2 mr-2">
                <label htmlFor="built">
                  Year Built
                  <input
                    id="built"
                    type="number"
                    min="0"
                    placeholder="Type Year Built"
                    value={yearBuilt}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeYearBuiltHandler(e)}
                  />
                </label>
              </div>
              <div className="w-1/2 ml-2">
                <label htmlFor="floors">
                  Floors / Stories
                  <input
                    id="floors"
                    type="number"
                    min="0"
                    placeholder="Type Floors / Stories"
                    value={floors}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeFloorsStoriesHandler(e)}
                  />
                </label>
              </div>
            </div>
            <div className="w-full text-base mt-2 flex items-center">
              <div className="w-1/2 mr-2">
                <label htmlFor="built">
                  Heating
                  <input
                    id="heating"
                    type="text"
                    placeholder="Type of Heating"
                    value={heating}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeHeatingHandler(e)}
                  />
                </label>
              </div>
              <div className="w-1/2 ml-2">
                <label htmlFor="floors">
                  Cooling
                  <input
                    id="cooling"
                    type="text"
                    placeholder="Type of Cooling"
                    value={cooling}
                    className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                    onChange={(e) => onChangeCoolingHandler(e)}
                  />
                </label>
              </div>
            </div>

            <div className="text-center mt-5">
              <input
                type="button"
                value="Save"
                onClick={handleSaveDetailsClick}
                className="w-1/2 bg-dark text-white font-light text-lg px-2 py-2 outline-none focus:outline-none"
              />
            </div>
          </form>
        </Modal>
      </>
    );
  }
);
export default HomeDetails;
