import React, { useRef, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const HomeDescriptions = ({ home, userType, own, onSave }) => {
  const modalDescriptionsRef = useRef(null);
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [loading, setLoading] = useState(false);

  const [descriptions, setDescriptions] = useState("");

  useEffect(() => {
    setDescriptions(home.descriptions);
  }, [home]);

  const showDescriptionsModal = () => {
    setShowDescriptions(true);
  };

  const hideDescriptionsModal = () => {
    setShowDescriptions(false);
    setLoading(false);
  };

  const onChangeDescriptionsHandler = (e) => {
    setDescriptions(e.currentTarget.value);
  };

  const handleSaveDetailsClick = async () => {
    let tempHomeData = {
      ...home,
      descriptions: descriptions,
    };

    onSave(tempHomeData);

    setShowDescriptions(false);
  };

  return (
    <>
      <div className="relative w-full mt-7 bg-light-blue-03 rounded p-3">
        <span className="text-xl text-dark font-bold">Description</span>
        {userType === "admin" && own && (
          <div
            onClick={() => showDescriptionsModal()}
            className="absolute flex right-4 top-4 cursor-pointer"
          >
            <img className="w-5" alt="edit" src="../assets/icons/edit.png" />
          </div>
        )}
      </div>
      <div className="w-full mt-5 leading-8">{home.descriptions}</div>
      <Modal
        ref={modalDescriptionsRef}
        open={showDescriptions}
        center
        initialFocusRef={modalDescriptionsRef}
        onClose={hideDescriptionsModal}
        animationDuration={400}
      >
        <PuffLoader
          color={"#378ABA"}
          loading={loading}
          css={override}
          size={50}
        />
        <div className="text-center my-5">
          <h1 className="text-dark text-2xl font-semibold">
            Edit Your Home Descriptions
          </h1>
        </div>
        <form action="">
          <div className="w-full text-base">
            <label htmlFor="descriptions">
              Description
              <textarea
                id="descriptions"
                type="text"
                rows={5}
                cols={5}
                placeholder="Type Description"
                value={descriptions}
                className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                onChange={(e) => onChangeDescriptionsHandler(e)}
              />
            </label>
          </div>

          <div className="text-center mt-5">
            <input
              type="button"
              value="Save"
              onClick={handleSaveDetailsClick}
              className="w-1/2 bg-dark text-white font-light text-lg px-2 py-2 outline-none focus:outline-none"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};
export default HomeDescriptions;
