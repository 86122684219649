import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

function Search({ parentCallback, handleSearch }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    // parentCallback(description);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        let temp = {
          latitude: lat,
          longitude: lng
        }
        parentCallback(temp);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="py-1 text-dark cursor-pointer" key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });  

  return (
    <div ref={ref}>
      <div className="relative flex flex-wrap items-stretch h-12 w-80 sm:w-96 my-2">
        <input 
          type="text" 
          id='address'
          value={value}
          disabled={!ready}
          onChange={handleInput}
          placeholder="Location Search" 
          className="px-3 py-2 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-base border border-primary shadow outline-none focus:outline-none focus:ring w-full pr-10"
        />
        <span onClick={ handleSearch } className="z-10 h-full leading-snug font-normal absolute text-center text-white absolute bg-primary rounded text-lg items-center justify-center w-10 right-0 py-3">
          <i className="fa fa-search"></i>
        </span>
      </div>
      {status === "OK" && <ul className="absolute p-2 border border-primary rounded-md bg-white" style={{zIndex: '10'}}>{renderSuggestions()}</ul>}
    </div>
  );
}
export default Search;