import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../components/Nav";
import Search from "../components/Search";
import FilterPriceRange from "../components/FilterPriceRange";
import FilterBedsBaths from "../components/FilterBedsBaths";
import HomeCard from "../components/HomeCard";
import FilterSqFeet from "../components/FilterSqFeet";
import { db } from "../firebase";
import { AuthContext } from "../providers/Authentication";

import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";

import { getPreciseDistance, convertDistance } from "geolib";
import FilterMoreRe from "../components/FilterMoreRe";
import Footer from "../components/Footer";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const GalleryPage = () => {
  const history = useHistory();
  const [address, setAddress] = useState("");
  const [minValue, setMinValue] = useState("0");
  const [maxValue, setMaxValue] = useState("Any");
  const [bedroomNum, setBedroomNum] = useState("Any");
  const [bathroomNum, setBathroomNum] = useState("Any");
  const [sqFeet, setSqFeet] = useState("1000+");
  const [poolOption, setPoolOption] = useState("Any");
  const [hoaOption, setHoaOption] = useState("Any");
  const [homeInforms, setHomeInforms] = useState([]);
  const [homeInformsTemp, setHomeInformsTemp] = useState([]);
  const [userType, setUserType] = useState("")

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const {currentUser} = useContext(AuthContext)

  const [currentPosition, setCurrentPosition] = React.useState({
    latitude: 32.92521,
    longitude: -97.14094,
  });

  const handleClick = (home) => {
    console.log("home=>", home)
    if((!!currentUser && userType === "admin") || (!!currentUser && home.sellerId === currentUser.UserUid) || home.status === "Active" || home.status === "Under Contract") {
      history.push({
        pathname: `/profile/${home.homeid}`,
        state: home.information,
      });
    }
  };

  const searchResult = (value) => {
    setAddress(value);
  };

  const handleLocationSearch = () => {
  };

  const filterPriceRange = (min, max) => {
    setMinValue(min);
    setMaxValue(max);
  };

  const filterBedsBaths = (bedNum, bathNum) => {
    setBedroomNum(bedNum);
    setBathroomNum(bathNum);
  };

  const filterSqFeet = (sqFeet) => {
    setSqFeet(sqFeet);
  };

  const filterMoreRe = (pool, hoa) => {
    setPoolOption(pool);
    setHoaOption(hoa);
  };

  useEffect(() => {
    const temp = homeInforms
      .filter((item) => {
        if (maxValue === "Any") {
          if (item.price * 1 >= minValue * 1) {
            return true;
          } else return false;
        } else {
          if (
            item.price * 1 >= minValue * 1 &&
            item.price * 1 <= maxValue * 1
          ) {
            return true;
          } else return false;
        }
      })
      .filter((item) => {
        if (bedroomNum === "Any" && bathroomNum === "Any") {
          return true;
        } else if (bedroomNum === "Any" && bathroomNum !== "Any") {
          let realBathNum = bathroomNum.substring(0, bathroomNum.length - 1);
          if (item.bathrooms * 1 >= realBathNum * 1) {
            return true;
          } else return false;
        } else if (bedroomNum !== "Any" && bathroomNum === "Any") {
          let realBedNum = bedroomNum.substring(0, bedroomNum.length - 1);
          if (item.bedrooms * 1 >= realBedNum * 1) {
            return true;
          } else return false;
        } else {
          let realBedNum = bedroomNum.substring(0, bedroomNum.length - 1);
          let realBathNum = bathroomNum.substring(0, bathroomNum.length - 1);
          if (
            item.bedrooms * 1 >= realBedNum * 1 &&
            item.bathrooms * 1 >= realBathNum * 1
          ) {
            return true;
          } else return false;
        }
      })
      .filter((item) => {
        let realSqFeetNum = sqFeet.substring(0, sqFeet.length - 1) * 1;
        if (item.squarefeet * 1 >= realSqFeetNum) {
          return true;
        } else return false;
      })
      .filter((item) => {
        if (poolOption === "Any" && hoaOption === "Any") {
          return true;
        } else if (poolOption === "Yes" && hoaOption === "Any") {
          if (!!item.pool && item.pool === true) {
            return true;
          } else return false;
        } else if (poolOption === "No" && hoaOption === "Any") {
          if (!item.pool || item.pool === false) {
            return true;
          } else return false;
        } else if (hoaOption === "Yes" && poolOption === "Any") {
          if (!!item.hoa && item.hoa === true) {
            return true;
          } else return false;
        } else if (hoaOption === "No" && poolOption === "Any") {
          if (!item.hoa || item.hoa === false) {
            return true;
          } else return false;
        } else if (poolOption === "Yes" && hoaOption === "Yes") {
          if (
            !!item.pool &&
            item.pool === true &&
            !!item.hoa &&
            item.hoa === true
          ) {
            return true;
          } else return false;
        } else if (poolOption === "Yes" && hoaOption === "No") {
          if (
            !!item.pool &&
            item.pool === true &&
            (!item.hoa || item.hoa === false)
          ) {
            return true;
          } else return false;
        } else if (poolOption === "No" && hoaOption === "Yes") {
          if (
            (!item.pool || item.pool === false) &&
            !!item.hoa &&
            item.hoa === true
          ) {
            return true;
          } else return false;
        } else {
          if (
            (!item.pool || item.pool === false) &&
            (!item.hoa || item.hoa === false)
          ) {
            return true;
          } else return false;
        }
      });

    const homeDataDisplay = temp.map((item) => {
      const { _lat, _long } = item.coordinates;
      let formattedMiles = 0;

      if (_lat && _long) {
        let distances = getPreciseDistance(
          { latitude: _lat, longitude: _long },
          {
            latitude: !!address ? address.latitude : currentPosition.latitude,
            longitude: !!address ? address.longitude : currentPosition.longitude,
          }
        );
        let miles = convertDistance(distances, "mi");
        
        if (miles < 10) {
          formattedMiles = miles.toFixed(1);
        } else {
          formattedMiles = miles.toFixed(0);
        }
      }
      
      return { ...item, information: formattedMiles };
    });
    homeDataDisplay.sort((a, b) =>
      a.information * 1 > b.information * 1 ? 1 : -1
    );
    setHomeInformsTemp(homeDataDisplay);
  }, [
    minValue,
    maxValue,
    bedroomNum,
    bathroomNum,
    sqFeet,
    poolOption,
    hoaOption,
    address,
    homeInforms,
  ]);

  useEffect(() => {
    setLoading(true);
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then(
        (result) => {
          let temp = {
            latitude: result.latitude,
            longitude: result.longitude,
          };
          setCurrentPosition(temp);
          fetchHomeInforms(temp);
        },
        (error) => {
          fetchHomeInforms(currentPosition);
        }
      );
  }, []);

  const fetchHomeInforms = async (temp) => {
    try {
      const homeData = [];
      const response = db.collection("homes");
      const data = await response.get();
      let userData = []
      if(!!currentUser) {
        const query = await db
          .collection("users")
          .where("uid", "==", currentUser.UserUid)
          .get();
        if (query.docs.length > 0) {
          userData = await query.docs[0].data();
          setUserType(userData.type)
        }
      }
      data.docs.map((item) => {
        let homeItem = item.data()      
        
        if(!!currentUser && userData.type === "admin") {
          homeData.push(homeItem)
        } else if(!!currentUser && currentUser.UserUid === homeItem.sellerId) {
          homeData.push(homeItem)
        } else if(homeItem.status === "Active" || homeItem.status === "Under Contract") {
          homeData.push(homeItem)
        }
      });
      console.log("gallery homeData=>", homeData)
      setHomeInforms(homeData);
      const homeDataDisplay = homeData.map((item) => {
        const { _lat, _long } = item.coordinates;

        let formattedMiles = 0;

        if (_lat && _long) {
          let distances = getPreciseDistance(
            { latitude: _lat, longitude: _long },
            { latitude: temp.latitude, longitude: temp.longitude }
          );
          let miles = convertDistance(distances, "mi");
          console.log("gallery miles=>", miles, item)
          
          if (miles < 10) {
            formattedMiles = miles.toFixed(1);
          } else {
            formattedMiles = miles.toFixed(0);
          }
        }
        
        return { ...item, information: formattedMiles };
      });
      homeDataDisplay.sort((a, b) =>
        a.information * 1 > b.information * 1 ? 1 : -1
      );
      setHomeInformsTemp(homeDataDisplay);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <div className="w-full">
      <Nav />
      <div className="bg-secondary">
        <div className="max-w-7xl block lg:flex items-center justify-between mx-auto py-1 mt-1 px-4 sm:px-6 lg:px-8">
          <Search
            parentCallback={searchResult}
            handleSearch={handleLocationSearch}
          />
          <div className="block sm:flex items-center">
            <FilterPriceRange parentCallback={filterPriceRange} />
            <FilterBedsBaths parentCallback={filterBedsBaths} />
            <FilterSqFeet parentCallback={filterSqFeet} />
            <FilterMoreRe parentCallback={filterMoreRe} />
          </div>
        </div>
      </div>
      <div className="max-w-7xl block md:flex items-center justify-between mx-auto my-2 px-4 sm:px-6 lg:px-8 mt-3">
        <div className="text-dark text-base font-normal opacity-50">
          {homeInformsTemp.length} Homes Available on Smart Property Marketing
        </div>
        <div></div>
      </div>

      {error !== null && (
        <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
          {error}
        </div>
      )}

      <div className="max-w-7xl block items-center justify-between mx-auto my-2 px-4 sm:px-6 lg:px-8 mt-3">
        <PuffLoader color={"#378ABA"} loading={loading} css={override} size={100} />
        {homeInformsTemp &&
          homeInformsTemp.map((home, i) => (
            <div
              key={i}
              className="w-full md:w-1/2 lg:w-1/3 inline-block"
              onClick={() => handleClick(home)}
            >
              <HomeCard home={home} />
            </div>
          ))}
      </div>
      <Footer />
    </div>
  );
};

export default GalleryPage;
