import React, { useState, useRef, useEffect, useContext } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Calculator from "../components/Calculator";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { auth, db } from "../firebase";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import { useHistory, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../providers/Authentication";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const SellYourHomePage = () => {
  const history = useHistory();
  const signUpRef = useRef(null);
  const calculatorRef = useRef(null);
  const [error, setError] = useState(null);
  const [color, setColor] = useState("#378ABA");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [zipCode, setZipCode] = React.useState("");
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (currentUser) {
      confirmAlert({
        title: "Alert",
        message: "You are already signed in.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    } else {
      setLoading(true);

      let email = e.target.elements.email?.value;
      let password = e.target.elements.password?.value;
      let firstname = e.target.elements.firstname?.value;
      let lastname = e.target.elements.lastname?.value;
      let phone = e.target.elements.phone?.value;

      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        setError(null);

        const msg = {
          personalizations: [
            {
              to: [
                {
                  email: "forms@smartpropertymktg.com",
                  name: "forms@smartpropertymktg.com",
                },
              ],
            },
          ],
          from: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          reply_to: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          subject: `New Seller Profile - ${firstname} ${lastname}`,
          content: [
            {
              type: "text/html",
              value: `<!doctype html>
              <html>
                <body>
                  <p>Account Type: Selling my home</p>
                  <p>Name: ${firstname} ${lastname}</p>
                  <p>Email: ${email}</p>
                  <p>Phone: ${phone}</p>
                  <p>Property Address: ${address}</p>
                  <p>User ID: ${user.uid}</p>
                </body>
              </html>
            `,
            },
          ],
        };

        const emailRes = await axios.post(
          "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        );

        // const contactRes = await axios
        //   .post(
        //     "https://us-central1-smartmktg.cloudfunctions.net/saveContact",
        //     {
        //       contact: {
        //         email,
        //         firstName: firstname,
        //         lastName: lastname,
        //         phone,
        //         type: "Selling my home",
        //         fieldValues:[
        //           {
        //             field: "1",
        //             value: address,
        //           },
        //           {
        //             field: "5",
        //             value: zipCode,
        //           }
        //         ]
        //       }
        //     },
        //     { headers: {
        //       "Content-Type": "application/json",
        //     } },
        //   );

        await db.collection("users").add({
          uid: user.uid,
          type: "Selling my home",
          email,
          firstname,
          lastname,
          phone,
          address,
          // campaignId: contactRes.data.contactTag.contact,
        });

        let ref = db.collection("homes").doc();
        await db.collection("homes").add({
          address: address,
          coordinates: coordinates,
          homeid: ref.id,
          sellerId: user.uid,
        });
        history.push({
          pathname: `/profile/${ref.id}`,
          state: "",
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  const callback = (value, location, zipCode) => {
    console.log("autocomplete return value=>", value, location);
    setAddress(value);
    setCoordinates({
      _lat: location.lat,
      _long: location.lng,
    });
    setZipCode(zipCode);
  };

  return (
    <div className="w-full">
      <Nav />
      <div className="relative">
        <div className="block bg-sell-mobile-header-texture md:bg-sell-header-texture bg-cover bg-no-repeat bg-center h-xl"></div>
      </div>

      <div className="max-w-7xl block md:flex md:justify-center mx-auto pt-10 md:pt-20 px-10">
        <div className="w-full h-xl md:h-auto md:flex-1 px-10 bg-fsbo-texture bg-cover bg-no-repeat bg-center"></div>
        <div className="md:flex-2 px-0 md:px-10 mt-10 md:mt-0">
          <div className="text-xl lg:text-3xl mt-6 md:mt-0 font-bold text-dark">
            Do It Yourself with Legal Confidence
          </div>
          <div className="text-sm text-dark mt-3 md:mt-5 md:leading-2 lg:leading-7">
            <p>
              No one knows your property better than you, the seller. Our
              clients are self-starters who realize how much they can save by
              handling the transaction on their own. We appreciate the purest
              form of the For Sale By Owner mentality. Our legally backed
              services help facilitate that mindset by providing a Seller’s
              Workbook that helps manage the process from start to close.
            </p>
          </div>
          <div className="text-xl lg:text-3xl mt-2 lg:mt-10 font-bold text-dark">
            Keep it Simple
          </div>
          <div className="text-sm text-dark mt-3 md:mt-5 md:leading-2 lg:leading-7">
            <p>
              Your time is valuable, so let’s not waste it. Our in-house process
              gives you control over everything. Our clients will only receive
              communications from a self-representing or real estate agent
              engaged buyer who has been vetted through a personal and banking
              background check. This puts you in the driver's seat to decide how
              and when your home is sold. You coordinate handling the showings
              on your time. No more phone calls that give you a moment’s notice
              that you need to leave your home for another showing.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="relative block md:flex justify-between mx-auto py-1 md:py-2 mt-24 md:items-center">
        <div className="w-full md:w-3/4 md:px-16 md:h-imgHeight">
          <img
            src="../assets/images/step1.jpeg"
            alt="sell-home1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full md:w-2/5 p-4 md:absolute bg-white md:border md:border-black right-16">
          <p className="text-lg font-bold">Step 1</p>
          <p className="text-3xl font-bold">Sign Up with SMART</p>
          <ul className="text-lg text-dark mt-3 md:mt-5 pl-6 md:leading-2 lg:leading-7">
            <li className="list-disc mt-2 font-bold">Create a free account</li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Register for your free account with SMART Property Marketing.
            </div>
            <li className="list-disc mt-2 font-bold">
              Build your SMART profile
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Use our proprietary services to upload your photos, begin building
              your property profile and set your asking price. You will have a
              link to promote your property on your own social networking
              mediums.
            </div>
            <li className="list-disc mt-2 font-bold">
              No hidden fees with SMART
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Everything is up front and honest. This includes running a title
              search on your property with the SMART Real Estate Legal Team,
              vetting your potential buyer’s and placing your yard sign. Once
              the title search is clear, you’ll be ready to post your property
              live on our site.
            </div>
          </ul>
        </div>
      </div>
      <div className="relative block md:flex md:flex-row-reverse justify-between mx-auto py-1 md:py-2 mt-24 md:items-center">
        <div className="w-full md:w-3/4 md:px-16 md:h-imgHeight">
          <img
            src="../assets/images/step2.jpeg"
            alt="sell-home1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full md:w-2/5 p-4 md:absolute bg-white md:border md:border-black left-16">
          <p className="text-lg font-bold">Step 2</p>
          <p className="text-3xl font-bold">Stage & Show It</p>
          <ul className="text-lg text-dark mt-3 md:mt-5 pl-6 md:leading-2 lg:leading-7">
            <li className="list-disc mt-2 font-bold">
              Put your best foot forward
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Nobody knows their home better than you. We believe you know how
              to stage your property for showing, but we have a guideline to
              help walk you through the process.
            </div>
            <li className="list-disc mt-2 font-bold">
              Book showings on your terms
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              When it comes to showing your home, that’s up to you. Show at your
              own pace and on your time. Only vetted buyers will step foot in
              your home, so your valuable time is spared of the dreaded window
              shopper.
            </div>
          </ul>
        </div>
      </div>
      <div className="relative block md:flex justify-between mx-auto py-1 md:py-2 mt-24 md:items-center">
        <div className="w-full md:w-3/4 md:px-16 md:h-imgHeight">
          <img
            src="../assets/images/step3.jpeg"
            alt="sell-home1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full md:w-2/5 p-4 md:absolute bg-white md:border md:border-black right-16">
          <p className="text-lg font-bold">Step 3</p>
          <p className="text-3xl font-bold">Sell It On Your Own</p>
          <ul className="text-lg text-dark mt-3 md:mt-5 pl-6 md:leading-2 lg:leading-7">
            <li className="list-disc mt-2 font-bold">Sell at your own pace</li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              SMART will connect you with vetted potential buyers and let you
              take the conversation from there. Giving you all the control to
              manage the selling process of your home.
            </div>
            <li className="list-disc mt-2 font-bold">
              Review offers and handle counter-offers
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              You will be notified when an offer has been made by a potential
              buyer. Our platform provides you with the capability to review
              offers, write counter offers and come to an agreement with your
              buyer on your terms.
            </div>
            <li className="list-disc mt-2 font-bold">
              Professional help from our team of experts is available when
              needed
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Plus, you are fully protected with paperwork, legal documents and
              disclosures between all parties.
            </div>
          </ul>
        </div>
      </div>
      <div className="relative block md:flex md:flex-row-reverse justify-between mx-auto py-1 md:py-2 mt-24 md:items-center">
        <div className="w-full md:w-3/4 md:px-16 md:h-imgHeight">
          <img
            src="../assets/images/step4.jpeg"
            alt="sell-home1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full md:w-2/5 p-4 md:absolute bg-white md:border md:border-black left-16">
          <p className="text-lg font-bold">Step 4</p>
          <p className="text-3xl font-bold">Close It</p>
          <ul className="text-lg text-dark mt-3 md:mt-5 pl-6 md:leading-2 lg:leading-7">
            <li className="list-disc mt-2 font-bold">
              Ready to accept an offer?
            </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              Once you’ve decided on an offer and you are ready to sell, contact
              our SMART Real Estate Legal Team to draft a contract and schedule
              a date for closing.
            </div>
            <li className="list-disc mt-2 font-bold">Closing time! </li>
            <div className="text-sm text-dark mt-1 md:leading-2 lg:leading-7">
              The SMART Real Estate Legal Team will be there to help facilitate
              your closing. Closings can be scheduled in-person or digitally if
              preferred.
            </div>
          </ul>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          onClick={() =>
            signUpRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            })
          }
          className="bg-primary text-white text-sm p-4 rounded-lg mt-6 w-72 text-center cursor-pointer"
        >
          <span className="mr-2">GET STARTED</span>
          <i className="fa-arrow-right fa"></i>
        </div>
      </div>
      <div className="lg:bg-sell-pricing-texture lg:bg-cover lg:bg-no-repeat lg:bg-center mt-10 flex flex-col lg:flex-row justify-center items-center">
        <div className="max-w-md bg-white rounded-md px-8 lg:py-12 lg:my-10">
          <div className="text-black text-2xl font-bold text-center tracking-wide">
            RISK FREE SERVICE
          </div>
          <div className="text-black font-bold text-5xl text-center py-4 border-b border-gray relative">
            $0
            <div className="absolute italic text-xs md:text-sm top-6 right-2 md:right-4 w-16 md:w-24">
              plus our closing fee
            </div>
          </div>
          <div className="text-dark-gray mt-10 text-sm">
            Get started with SMART Property Marketing for <b>FREE</b>. When
            you’re ready to go live, along with our&nbsp;
            <span
              onClick={() =>
                calculatorRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
              className="text-primary underline cursor-pointer"
            >
              closing fee*
            </span>
            , our marketing services are an upfront{" "}
            <b>
              <strike>$475</strike>
            </b>{" "}
            <b>$0</b> which includes a wide range of unique services that are
            vital to selling your home For Sale by Owner.
          </div>
          <div className="text-dark-gray mb-10 mt-4 text-xs italic">
            *SMART Property Marketing does take a fee upon closing, please refer
            to our&nbsp;
            <span
              onClick={() =>
                calculatorRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
              className="text-primary underline cursor-pointer"
            >
              SMART Fee Slider
            </span>
            &nbsp;for estimated fees.
          </div>
          <div className="flex justify-center">
            <div
              onClick={() =>
                signUpRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
              className="bg-primary text-white font-bold text-lg tracking-wide px-4 py-2 rounded-lg w-64 text-center cursor-pointer"
            >
              GET STARTED TODAY
            </div>
          </div>
        </div>
        <div className="max-w-md bg-dark lg:rounded-md lg:my-0 my-10 px-10 py-8">
          <div className="text-white text-2xl font-bold text-center border-b border-primary pb-4 tracking-wide">
            OUR MARKETING SERVICES
          </div>
          <ul className="text-sm text-gray ml-4 mt-8">
            <li className="list-disc mt-2 marker:color-primary">
              Access to SMART Real Estate Legal Team for Support
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              All Legal Documents and Disclosures from start to closing{" "}
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Title Search on your Property
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Vet Prospective Buyers with Personal and Banking Background Check{" "}
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Dedicated Property Profile on SMART Property Marketing Website
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Email and Chat Support with the SMART Team
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Professional Digital Marketing Flyer that can be emailed, texted
              or printed
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              Unlocked Access to our comprehensive Seller’s Workbook with
              resources and information
            </li>
            <li className="list-disc mt-2 marker:color-primary">
              For Sale Sign for your yard with QR Code
            </li>
          </ul>
        </div>
      </div>
      <div ref={calculatorRef}>
        <Calculator />
      </div>
      <div
        ref={signUpRef}
        className="max-w-7xl relative text-center mx-auto mb-16 px-6 md:px-32 lg:px-40"
      >
        <div className="text-black pt-8 md:pt-12 text-2xl md:text-4xl font-bold leading-snug">
          Become a SMART Seller today!
        </div>
        <div className="text-black mt-5 mb-6 text-lg md:text-xl italic">
          Enter your details to create your free sellers account and get started
          building your profile.
        </div>
        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}

        <PuffLoader color={color} loading={loading} css={override} size={50} />

        <form onSubmit={handleFormSubmit}>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="firstname"
                className="block text-left text-dark text-base"
              >
                First Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="firstname"
                placeholder="First name"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="lastname"
                className="block text-left text-dark text-base"
              >
                Last Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="lastname"
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="email"
                className="block text-left text-dark text-base"
              >
                Email
              </label>
              <input
                type="email"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="email"
                placeholder="Enter email here"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="password"
                className="block text-left text-dark text-base"
              >
                Password
              </label>
              <input
                type="password"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="password"
                placeholder="Enter password here"
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="phone"
                className="block text-left text-left text-dark text-base"
              >
                Phone # (Optional)
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="phone"
                placeholder="Enter Ph #"
              />
            </div>
            <div className="w-1/2 pl-2 mb-4 text-left">
              <PlacesAutocomplete parentCallback={callback} />
            </div>
          </div>

          <button
            type="submit"
            className={`mt-6 w-72 bg-primary py-2 px-4 text-base text-white rounded border border-primary`}
          >
            <span className="mr-2">CREATE MY FREE ACCOUNT</span>
            <i className="fa-arrow-right fa"></i>
          </button>
        </form>
        <div className="text-center mt-2">
          By creating an account, I agree to the{" "}
          <Link to="/terms" className="text-primary underline">
            Terms & Conditions.
          </Link>
        </div>
        <div className="text-center mt-2">
          Already have an account?&nbsp;&nbsp;
          <Link to="/login">
            <span className="text-primary underline">Sign in here</span>
          </Link>
          {/* <a href="#" className="text-primary underline">Sign in here</a> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SellYourHomePage;
