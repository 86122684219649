import React, { useState, useRef } from "react";
import Nav from '../components/Nav';
import Footer from "../components/Footer";

const OriginStoryPage = () => {
  return (
    <div className="w-full">
      <Nav />
      <div className="relative">
        <div className="block bg-origin-header-texture bg-cover bg-no-repeat bg-center w-full text-white text-center text-2xl md:text-4xl lg:text-5xl font-Cocogoose py-16 md:py-32 lg:py-40">
          <div>Our Origin Story</div>
        </div>
      </div>
      {/* <img src="../assets/images/origin_header.png" alt="our-story" className="w-full" />
      <p className="text-xl md:text-4xl lg:text-5xl text-center font-Cocogoose py-8 md:py-16">Our Origin Story</p> */}

      <div className="pt-10 px-10 md:px-24 lg:px-32 lg:pt-24 text-md md:text-lg lg:text-xl">
        “The more honest you are with your business, about who you serve and the problems that you solve, the more loyalty you will find”. 
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        During my life, I’ve listed and sold several homes using a real estate agency. Typically, the experience went as expected, and the final sales price was usually very close to or above my listing price. I’ll never forget the feeling I had when it came time to authorize upwards of 6% commission at every closing. It was substantial! 
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl italic">
        Like most of us, the sale or purchase of a home is the largest transaction of our lifetime and giving up my hard-earned equity at closing made a lasting impression.
      </div>

      <div className="p-10 md:py-8 md:px-24 lg:px-32 text-md md:text-xl lg:text-2xl font-bold">
        I very specifically remember thinking, <i className="text-primary">“There must be a better way“</i>.
      </div>
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        Some time ago, I was considering selling my next home.  I inherited a Do-It-Yourself mentality and naturally embrace any challenge to do things on my own when I can, so in order to save as much of the 6% sales commission as I could, I decided to take on the For Sale By Owner approach. 
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        Selling my home on my own provided the ability to control as much of the process and transaction as I could.  I know that certain expenses are basic costs regardless of how you choose to sell the home in addition to costs associated with any repairs or renovations, inspections, and an appraisal.  I knew of the possibility of Seller Concessions and the approximate 1% Closing Costs. 
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        I was confident I could represent my property as well as anyone but was immediately tested with a couple of key issues. How would I strategically price my home and what could I do to market my property as professionally as possible? I did the best I could with taking pictures, creating a description of the home and bought a yard sign complete with a clear tube to hold my printed flyers and placed the sign in my yard. I mostly found myself dealing with curious neighbors, until I received an offer. That’s when I realized I was in over my head.  What do I do with that offer?  Do I have the proper documents or agreements to protect myself from any legal issues? Do I have a clear title for my home? How do I know the buyer is qualified to make the offer? 
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        I was overwhelmed and decided not to sell my property.  I simply was not prepared.
      </div>

      <div className="p-10 md:py-8 md:px-24 lg:px-32 text-md md:text-xl lg:text-2xl font-bold text-primary">
        What if a pure For Sale By Owner online service existed?
      </div>
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        The answer came when I was visiting with a Real Estate Attorney I’ve known for years.  I brought up the idea of a For Sale By Owner marketing service. We discussed the entire process at length. How could a property owner list, sell and legally close the transaction on their own? Could we partner with a team of Real Estate Attorney’s to help legalize the entire transaction? Why not create a company that provides a dual, full-service platform to help the For Sale By Owner client?  
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        The idea of SMART Property Marketing was born.
      </div>
      <br />
      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
      SMART is inspired to provide a user-friendly, online platform that helps create an immensely detailed property profile, the legal backing all the way through closing and the <i className="font-bold">seller gets to keep up to a mind-boggling 75% of the fee</i>.
      </div>

      <div className="w-4/5 md:w-3/5 text-white flex mx-auto my-10 md:my-20 justify-center items-center bg-primary rounded-2xl text-md md:text-xl lg:text-2xl text-center px-10 md:px-16 py-6 md:py-10 italic">
        We are not a Real Estate Agency.  We are not backed by a Real Estate Company.  We do not list your home on MLS.  Our Seller Profiles are exclusive to our website and kept in-house.
      </div>

      <div className="px-10 md:px-24 lg:px-32 text-md md:text-lg lg:text-xl">
        Born purely from the willingness to help the Do-It-Yourself, For Sale By Owner individual, we are proud to offer a unique option for those of the mindset and are confident enough to handle the transaction on their own and keep the years of hard-earned equity in their own pocket!
      </div>

      <div className="px-10 py-8 md:py-16 md:px-24 lg:px-32 text-md md:text-xl lg:text-2xl font-bold">
        Ron Davis, Co-Founder, SMART Property Marketing
      </div>
      {/* <div className="px-10 pb-8 md:pb-16 md:px-24 lg:px-32 text-md md:text-xl lg:text-2xl font-bold">
        Co-founder, and Managing Partner of <i className="text-primary">SMART Property Marketing</i>
      </div> */}
      <Footer />
    </div>
  )
};

export default OriginStoryPage;
