import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAlTmQfN3mJZYweFxPcYyMctOK1TRXb8pA",
  authDomain: "smartmktg.firebaseapp.com",
  databaseURL: "https://smartmktg-default-rtdb.firebaseio.com",
  projectId: "smartmktg",
  storageBucket: "smartmktg.appspot.com",
  messagingSenderId: "1079714051217",
  appId: "1:1079714051217:web:8b34c153ac586a790bbb30",
  measurementId: "G-GYWQDKTZTK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();