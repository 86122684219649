import React, { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const PlacesAutocomplete = ({ parentCallback, address }) => {
  useEffect(() => {
    setValue(address)
  }, [address]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description, place_id }) => async () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    // parentCallback(description);

    // Get latitude and longitude via utility functions
    const results = await getGeocode({ address: description });
    if (results) {
      const {lat, lng} = await getLatLng(results[0]);
      const zipCode = await getZipCode(results[0], false);
      const parameter = {placeId: place_id};
      const details = await getDetails(parameter);
      parentCallback(description, { lat, lng }, zipCode, details);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li className="py-1 text-dark cursor-pointer" key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <label htmlFor='address' className="text-dark text-base">Property Address</label>
      <div className="relative flex w-full flex-wrap items-stretch">
        <span className="z-10 h-full leading-normal font-normal absolute text-center text-dark opacity-50 absolute bg-transparent rounded text-lg items-center justify-center w-8 pl-3 py-2">
          <i className="fa fa-search"></i>
        </span>
        <input 
          type="text" 
          id='address' 
          value={value}
          disabled={!ready}
          onChange={handleInput}
          placeholder="Search location" 
          className="p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out w-full pl-10"
        />
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul className="p-2 border border-primary rounded-md">{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutocomplete;