import React from "react";
import CarouselItem from "./CarouselItem";

const HomeCard = ({ home }) => {
  const [favourite, setFavourite] = React.useState(false);

  const favouriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFavourite(!favourite);
  };

  return (
    <div className="shadow-lg rounded-lg m-4 relative pb-2">
      <CarouselItem images={!!home && !!home.images ? home.images : []} />
      <div className="flex justify-between items-center px-4 py-3">
        <span className="text-xl text-black">
          $
          {!!home && !!home.price
            ? home.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : ""}
        </span>
        <div onClick={(e) => favouriteClick(e)}>
          <img
            className="w-6"
            alt="card_favourite"
            src={
              favourite
                ? "assets/icons/favourite1.png"
                : "assets/icons/favourite0.png"
            }
          />
        </div>
      </div>
      <div className="flex justify-between items-center px-4 mt-2">
        <div className="text-base text-dark flex items-start">
          <img
            className="w-6"
            alt="card_bedroom"
            src="assets/icons/bedroom.png"
          />
          <span className="pl-2">
            {!!home && !!home.bedrooms ? home.bedrooms : ""} Bedrooms
          </span>
        </div>
        <div className="text-base text-dark flex items-start">
          <img
            className="w-6"
            alt="card_bathtub"
            src="assets/icons/bathtub.png"
          />
          <span className="pl-2">
            {!!home && !!home.bathrooms ? home.bathrooms : ""} Bathrooms
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center px-4 mt-2">
        <div className="text-base text-dark flex items-start">
          <img
            className="w-6"
            alt="card_squares"
            src="assets/icons/squares.png"
          />
          <span className="pl-2">
            {!!home && !!home.squarefeet ? home.squarefeet : ""} Square Feet
          </span>
        </div>
        <div className="text-base text-dark flex items-start">
          <img className="w-6" alt="card_acreage" src="assets/icons/pin.png" />
          <span className="pl-2">
            {!!home && !!home.lotsize ? home.lotsize : ""} Acres
          </span>
        </div>
      </div>
      <div className="text-base text-dark flex items-center px-4 mt-2 mb-4 h-10">
        <img className="w-6" alt="card_pin" src="assets/icons/pin.png" />
        <span className="pl-3 text-sm">
          {!!home && !!home.address ? home.address : ""}
        </span>
      </div>
      <div className="absolute bg-dark text-white rounded top-2.5 left-2.5 px-4 py-1 flex items-center">
        <img
          className="w-6"
          alt="card_pin_white"
          src="assets/icons/pin_white.png"
        />
        <span className="pl-2">
          {!!home && !!home.information ? home.information : ""} mi
        </span>
      </div>
    </div>
  );
};

export default HomeCard;
