import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Nav from '../components/Nav';

const mapStyles = {
  width: '100%',
  height: 'calc(100% - 80px)',
  position: 'absolute'
};

const MapPage = () => {
  const { state: data } = useLocation();
  console.log("Map data=>", data)
  const { coordinates, address } = data
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})

  const showAddress = (props, marker, e) => {
    setActiveMarker(marker)
    setTimeout(() => {
      setShowingInfoWindow(true)
    }, 300); 
  }

  const onMapClicked = () => {
    setShowingInfoWindow(false)
    setActiveMarker(null)
  }

  return (
    <div className="w-full">
      <Nav/>
      <div style={mapStyles}>
        <Map
          google={window.google}
          zoom={8}
          initialCenter={{ lat: coordinates._lat, lng: coordinates._long}}
          onClick={() => onMapClicked()}
        >
          <Marker position={{ lat: coordinates._lat, lng: coordinates._long}} onClick={showAddress}/>
          <InfoWindow
            marker={activeMarker}
            visible={showingInfoWindow}>
              <div>
                <h1>{address}</h1>
              </div>
          </InfoWindow>
        </Map>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBKhJQEsBURouPrjQ7EsXBNWZ9YT_z46U0'
})(MapPage);