import React from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import logoBase64Img from "./logoBase64Img";
import axios from "axios";

const GeneratePDF = (pdfData, campaignId, dealId) => {
  const { 
    reqFirstName, 
    reqLastName, 
    reqEmail, 
    reqPhone, 
    price, 
    sellerFirstName, 
    sellerLastName, 
    sellerEmail, 
    sellerPhone, 
    propertyAddress, 
    propertyZipCode, 
    propertyCity, 
    propertyState,
    formatedClosingDate
  } = pdfData;
  
  const current = new Date();
  const date = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const topheaderStyle = {
    0: { cellWidth: 350 },
    1: { halign: 'right' },
    2: { cellWidth: 'auto', fillColor: [223, 228, 240], textColor: [108, 93, 128] }
  }
  const headerStyle = { cellWidth: 'auto', halign: 'center', fontSize: 18, fillColor: [71, 161, 213] };
  const bodySellerUpStyle = { 
    0: { cellWidth: 90 }, 
    1: { cellWidth: 90, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
    2: { cellWidth: 90 },
    3: { cellWidth: 40, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] }, 
    4: { cellWidth: 90 },
    5: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },  
  };
  const bodySellerBottomStyle = { 
    0: { cellWidth: 90 }, 
    1: { cellWidth: 220, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] }, 
    2: { cellWidth: 90 },
    3: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
  };
  const bodyPropertyUpStyle = { 
    0: { cellWidth: 100 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  };
  const bodyPropertyBottomStyle = { 
    0: { cellWidth: 40 }, 
    1: { cellWidth: 90, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] }, 
    2: { cellWidth: 40 },
    3: { cellWidth: 220, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
    4: { cellWidth: 60 },
    5: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
  };
  const bodyOfferCheckStyle = { 
    0: { cellWidth: 90 }, 
    1: { cellWidth: 40, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] }, 
    2: { cellWidth: 150, halign: 'right' },
    3: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
  };
  const bodyOffer7Style = {
    0: { cellWidth: 220 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  }
  const bodyOffer8Style = {
    0: { cellWidth: 150 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  }
  const bodyOffer9Style = {
    0: { cellWidth: 200 }, 
    1: { cellWidth: 40, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
    2: { cellWidth: 'auto', halign: 'left' } 
  }
  const bodyOffer10Style = {
    0: { cellWidth: 200 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  }
  const bodyOffer15Style = {
    0: { cellWidth: 350 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  }
  const bodyOffer17Style = {
    0: { cellWidth: 250 }, 
    1: { cellWidth: 40, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
    2: { cellWidth: 100, halign: 'left' },
    3: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  }
  const bodyOffer22Style = {
    0: { cellWidth: 230 }, 
    1: { cellWidth: 40, halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] },
    2: { cellWidth: 'auto', halign: 'left' }
  }
  const bodySignatureStyle = {
    0: { cellWidth: 'auto', halign: 'left' }, 
    1: { cellWidth: 'auto', halign: 'right' }
  }
  const bodySignatureBottomStyle = { 
    0: { cellWidth: 200 }, 
    1: { cellWidth: 'auto', halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] } 
  };


  const topheader = {
    columnStyles: topheaderStyle,
    body: [[
      'This document does not constitute a legally binding agreement. It is merely to outline an agreement of terms. If you`re unsure of any line item, leave it blank in the initial offer.',
      'Date:', `${date}`
    ]]
  }
  const headerSeller = {
    headStyles: headerStyle, 
    head: [["Seller Information"]]
  };
  const bodySeller1 = {
    theme: "plain",
    columnStyles: bodySellerUpStyle,
    body: [['First Name:', `${sellerFirstName}`,'Middle Initial:', '', 'Last Name:', `${sellerLastName}`]]
  };
  const bodySeller2 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Email Address:', `${sellerEmail}`, 'Phone Number:', `${sellerPhone}`]]
  };
  const headerBuyer = {
    headStyles: headerStyle, 
    head: [["Buyer Information"]]
  };
  const bodyBuyer1 = {
    theme: "plain",
    columnStyles: bodySellerUpStyle,
    body: [['First Name:', `${reqFirstName}`,'Middle Initial:', '', 'Last Name:', `${reqLastName}`]]
  };
  const bodyBuyer2 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Email Address:', `${reqEmail}`, 'Phone Number:', `${reqPhone}`]]
  };
  const headerProperty = {
    headStyles: headerStyle, 
    head: [["Property Information"]],
  };
  const bodyProperty1 = {
    theme: "plain",
    columnStyles: bodyPropertyUpStyle,
    body: [['Current Address:', `${propertyAddress}`]]
  };
  const bodyProperty2 = {
    theme: "plain",
    columnStyles: bodyPropertyBottomStyle,
    body: [['City:', `${propertyCity}`, 'State:', `${propertyState}`, 'Zip Code:', `${propertyZipCode}`]]
  };
  const headerOffer = {
    headStyles: headerStyle, 
    head: [["Offer Details"]],
  };
  const bodyOffer1 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [
      ['Offer Price:', `$${price}`, 'Loan Amount:', '']
    ]
  };
  const bodyOffer2 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Lender Name:', '', 'Contact Name:', '']]
  };
  const bodyOffer3 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Email Address:', '', 'Phone Number:', '']]
  };
  const bodyOffer4 = {
    theme: "plain",
    columnStyles: bodyOfferCheckStyle,
    body: [['Cash Portion?', '', 'Cash Portion Amount:', '']]
  }
  const bodyOffer5 = {
    theme: "plain",
    columnStyles: bodyOfferCheckStyle,
    body: [['Earnest Money?', '', 'Earnest Money Amount:', '']]
  }
  const bodyOffer6 = {
    theme: "plain",
    columnStyles: bodyOfferCheckStyle,
    body: [['Option Fee?', '', 'Option Fee Amount:', '']]
  }
  const bodyOffer7 = {
    theme: "plain",
    columnStyles: bodyOffer7Style,
    body: [['Number of days needed for the option period?', '']]
  }
  const bodyOffer8 = {
    theme: "plain",
    columnStyles: bodyOffer8Style,
    body: [['Who will pay for the title policy?', '']]
  }
  const bodyOffer9 = {
    theme: "plain",
    columnStyles: bodyOffer9Style,
    body: [['Does the seller have an existing survey?', '', 'If yes, please provide a copy.']]
  }
  const bodyOffer10 = {
    theme: "plain",
    columnStyles: bodyOffer10Style,
    body: [['If no, who will pay for the survey?', '']]
  }
  const bodyOffer11 = {
    theme: "plain",
    columnStyles: bodyOffer9Style,
    body: [['Is the property located in an HOA?', '', 'If yes, please provide HOA contact information.']]
  }
  const bodyOffer12 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['HOA Name:', '', 'Contact Name:', '']]
  }
  const bodyOffer13 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Email Address:', '', 'Phone Number:', '']]
  }
  const bodyOffer14 = {
    theme: "plain",
    columnStyles:bodyOffer10Style,
    body: [['Who will be paying HOA transfer fees?', '']]
  }
  const bodyOffer15 = {
    theme: "plain",
    columnStyles: bodyOffer15Style,
    body: [['Does the buyer accept the home "as is" or will repairs need to be made?', '']]
  }
  const bodyOffer161 = {
    theme: "plain",
    body: [
      ['If repairs are needed, please describe in detail:'], 
    ]
  }
  const bodyOffer162 = {
    theme: "plain",
    columnStyles: {
      0: { halign: 'left', fillColor: [223, 228, 240], textColor: [108, 93, 128] }
    },
    body: [
      ['']
    ]
  }
  const bodyOffer17 = {
    theme: "plain",
    columnStyles: bodyOffer17Style,
    body: [['Will there be an allowance given in lieu of repairs?', '', 'Allowance Amount:', '']]
  }
  const bodyOffer18 = {
    theme: "plain",
    columnStyles: bodyOffer8Style,
    body: [['When would you like to close?', `${formatedClosingDate}`]]
  }
  const bodyOffer19 = {
    theme: "plain",
    columnStyles: bodyOffer7Style,
    body: [['Will the seller be paying for a home warranty?', '']]
  }
  const bodyOffer20 = {
    theme: "plain",
    columnStyles: bodyOffer7Style,
    body: [['Is the seller living in the property at this time?', '']]
  }
  const bodyOffer21 = {
    theme: "plain",
    columnStyles: bodyOffer7Style,
    body: [['Was the home built prior to 1960?', '']]
  }
  const bodyOffer22 = {
    theme: "plain",
    columnStyles: bodyOffer22Style,
    body: [['Does the buyer have a Real Estate Agent?', '', 'If yes, please provide Real Estate Agent Information.']]
  }
  const bodyOffer23 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Agency:', '', 'Agent Name:', '']]
  }
  const bodyOffer24 = {
    theme: "plain",
    columnStyles: bodySellerBottomStyle,
    body: [['Email Address:', '', 'Phone Number:', '']]
  }
  const bodyOffer25 = {
    theme: "plain",
    body: [
      ['Additional notes and or agreed upon terms:'], 
      ['']
    ]
  }
  const bodyOffer26 = {
    theme: "plain",
    body: [['I agree to the terms as outlines above and I’m ready for a contract to be drafted with the terms outlined above.']]}
  const headerSellerSignature = {
    headStyles: headerStyle, 
    head: [['Seller Signature']],
  };
  const bodySellerSignature1 = {
    theme: "plain",
    columnStyles: bodySignatureStyle,
    body: [['X', '(e-Signature)']]
  }
  const bodySellerSignature2 = {
    theme: "plain",
    columnStyles: bodyPropertyUpStyle,
    // body: [['Seller’s Name:', `${sellerFirstName} ${sellerLastName}`]]
    body: [['Seller’s Name:', '']]
  };
  const bodySellerSignature3 = {
    theme: "plain",
    columnStyles: bodySignatureStyle,
    body: [['X', '(e-Signature)']]
  }
  const bodySellerSignature4 = {
    theme: "plain",
    columnStyles: bodySignatureBottomStyle,
    body: [['Secondary Seller’s Name (if needed):', '']]
  };
  const headerBuyerSignature = {
    headStyles: headerStyle,
    head: [['Buyer Signature']],
  }
  const bodyBuyerSignature1 = {
    theme: "plain",
    bodyStyles: { valign: "top" },
    columnStyles: bodySignatureStyle,
    body: [['X', '(e-Signature)']]
  }
  const bodyBuyerSignature2 = {
    theme: "plain",
    columnStyles: bodyPropertyUpStyle,
    // body: [['Buyer’s Name:', `${reqFirstName} ${reqLastName}`]]
    body: [['Buyer’s Name:', '']]
  };
  const bodyBuyerSignature3 = {
    theme: "plain",
    columnStyles: bodySignatureStyle,
    body: [['X', '(e-Signature)']]
  }
  const bodyBuyerSignature4 = {
    theme: "plain",
    columnStyles: bodySignatureBottomStyle,
    body: [['Secondary Buyer’s Name (if needed):', '']]
  };
  
  doc.autoTable({
    startY: doc.autoTable() + 70,
  
    margin: { horizontal: 0 },
    styles: { overflow: "linebreak" },
    bodyStyles: { valign: "top" },
    theme: "plain",
    showHead: "everyPage",
    didDrawPage: function (data) {  
      // Header
      doc.addImage(logoBase64Img, 'png', data.settings.margin.left + 45, 20, 150, 45);
      doc.setFontSize(15);
      doc.setTextColor(0,0,0);
      doc.setFillColor(15, 24, 53);
      doc.text("Offer and Acceptance Worksheet", data.settings.margin.left + 320, 50);
    }
  });
  
  doc.autoTable(topheader);
  doc.autoTable(headerSeller);
  doc.autoTable(bodySeller1);
  doc.autoTable(bodySeller2);
  doc.autoTable(headerBuyer);
  doc.autoTable(bodyBuyer1);
  doc.autoTable(bodyBuyer2);
  doc.autoTable(headerProperty);
  doc.autoTable(bodyProperty1);
  doc.autoTable(bodyProperty2);
  doc.autoTable(headerOffer);
  doc.autoTable(bodyOffer1);
  doc.autoTable(bodyOffer2);
  doc.autoTable(bodyOffer3);
  doc.autoTable(bodyOffer4);
  doc.autoTable(bodyOffer5);
  doc.autoTable(bodyOffer6);
  doc.autoTable(bodyOffer7);
  doc.autoTable(bodyOffer8);
  doc.autoTable(bodyOffer9);
  doc.autoTable(bodyOffer10);
  doc.autoTable(bodyOffer11);
  doc.autoTable(bodyOffer12);
  doc.autoTable(bodyOffer13);
  doc.autoTable(bodyOffer14);
  doc.autoTable(bodyOffer15);
  doc.autoTable(bodyOffer161);
  doc.autoTable(bodyOffer162);
  doc.autoTable(bodyOffer17);
  doc.autoTable(bodyOffer18);
  doc.autoTable(bodyOffer19);
  doc.autoTable(bodyOffer20);
  doc.autoTable(bodyOffer21);
  doc.autoTable(bodyOffer22);
  doc.autoTable(bodyOffer23);
  doc.autoTable(bodyOffer24);
  doc.autoTable(bodyOffer25);
  doc.autoTable(bodyOffer26);
  doc.autoTable(headerSellerSignature);
  doc.autoTable(bodySellerSignature1);
  doc.autoTable(bodySellerSignature2);
  doc.autoTable(bodySellerSignature3);
  doc.autoTable(bodySellerSignature4);
  doc.autoTable(headerBuyerSignature);
  doc.autoTable(bodyBuyerSignature1);
  doc.autoTable(bodyBuyerSignature2);
  doc.autoTable(bodyBuyerSignature3);
  doc.autoTable(bodyBuyerSignature4);

  doc.save("report.pdf")

  const temp = doc.output("datauristring")
  const base64Str = temp.replace('data:application/pdf;filename=generated.pdf;base64,','');
  // data:application/pdf;filename=generated.pdf;base64,

  const msgPDF = {
    personalizations: [
      {
        to: [
          {
            email: "offers@smartpropertymktg.com",
            name: "offers@smartpropertymktg.com",
          },
        ],
      },
    ],
    from: {
      email: "forms@smartpropertymktg.com",
      name: "offers@smartpropertymktg.com",
    },
    reply_to: {
      email: "forms@smartpropertymktg.com",
      name: "offers@smartpropertymktg.com",
    },
    subject: `New Offer Received on ${propertyAddress}`,
    content: [
      {
        type: "text/html",
        value: `<!doctype html>
        <html>
          <body>
            <p>A buyer submitted an offer on the website and the initial offer worksheet has been generated and attached to this email.</p>
            <br/>
            <p>Buyer</p>
            <p>Name: ${reqFirstName} ${reqLastName}</p>
            <p>Active Campaign Contact: https://smartpropertymarketing.activehosted.com/app/contacts/${campaignId}</p>
            <br/>
            <p>Property</p>
            <p>Address: ${propertyAddress}</P>
            <p>Active Campaign Deal: https://smartpropertymarketing.activehosted.com/app/deals/${dealId}</P>
          </body>
        </html>
      `,
      },
    ],
    attachments: [
      {
        content: base64Str,
        filename: "Worksheet.pdf",
        type: "application/pdf",
        disposition: "attachment"
      }
    ],
  };
  
  axios
    .post(
      "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
      msgPDF,
      { headers: { "Content-Type": "application/json" } }
    );
}

export default GeneratePDF;