import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Nav from "../components/Nav";
import HomeCard from "../components/HomeCard";
import { auth, db } from "../firebase";
import { css } from "@emotion/react";
import PuffLoader from "react-spinners/PuffLoader";
import { getPreciseDistance, convertDistance } from "geolib";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../components/Footer";
import Calculator from "../components/Calculator";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { AuthContext } from "../providers/Authentication";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const override = css`
  display: block;
  margin: 0 auto;
  border-color: "#378ABA";
`;

const LandingPage = () => {
  const history = useHistory();
  const signUpRef = useRef(null);
  const [error, setError] = useState(null);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [zipCode, setZipCode] = useState("");
  const [userType, setUserType] = useState("");

  const { currentUser } = useContext(AuthContext);

  const images = ["assets/images/slider-2.png", "assets/images/slider-3.png"];

  const [homeInformsTemp, setHomeInformsTemp] = useState([]);
  const [homeInformsTemp3, setHomeInformsTemp3] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState("#378ABA");
  const currentPosition = {
    latitude: 32.92521,
    longitude: -97.14094,
  };

  const handleClick = (home) => {
    history.push({
      pathname: `/profile/${home.homeid}`,
      state: home.information,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (currentUser) {
      confirmAlert({
        title: "Alert",
        message: "You are already signed in.",
        buttons: [
          {
            label: "Ok",
            onClick: () => {},
          },
        ],
      });
    } else {
      setLoading(true);

      let email = e.target.elements.email?.value;
      let password = e.target.elements.password?.value;
      let firstname = e.target.elements.firstname?.value;
      let lastname = e.target.elements.lastname?.value;
      let phone = e.target.elements.phone?.value;

      try {
        const { user } = await auth.createUserWithEmailAndPassword(
          email,
          password
        );
        setError(null);

        const msg = {
          personalizations: [
            {
              to: [
                {
                  email: "forms@smartpropertymktg.com",
                  name: "forms@smartpropertymktg.com",
                },
              ],
            },
          ],
          from: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          reply_to: {
            email: "forms@smartpropertymktg.com",
            name: "forms@smartpropertymktg.com",
          },
          subject: `New Seller Profile - ${firstname} ${lastname}`,
          content: [
            {
              type: "text/html",
              value: `<!doctype html>
              <html>
                <body>
                  <p>Account Type: Selling my home</p>
                  <p>Name: ${firstname} ${lastname}</p>
                  <p>Email: ${email}</p>
                  <p>Phone: ${phone}</p>
                  <p>Property Address: ${address}</p>
                  <p>User ID: ${user.uid}</p>
                </body>
              </html>
            `,
            },
          ],
        };

        const emailRes = await axios.post(
          "https://us-central1-smartmktg.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        );

        // const contactRes = await axios.post(
        //   "https://us-central1-smartmktg.cloudfunctions.net/saveContact",
        //   {
        //     contact: {
        //       email,
        //       firstName: firstname,
        //       lastName: lastname,
        //       phone,
        //       type: "Selling my home",
        //       fieldValues: [
        //         {
        //           field: "1",
        //           value: address,
        //         },
        //         {
        //           field: "5",
        //           value: zipCode,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //   }
        // );

        await db.collection("users").add({
          uid: user.uid,
          type: "Selling my home",
          email,
          firstname,
          lastname,
          phone,
          address,
          // campaignId: contactRes.data.contactTag.contact,
        });

        let ref = db.collection("homes").doc();
        await db.collection("homes").add({
          address: address,
          coordinates: coordinates,
          homeid: ref.id,
          sellerId: user.uid,
        });
        setLoading(false);
        history.push({
          pathname: `/profile/${ref.id}`,
          state: "",
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  const callback = (value, location, zipCode) => {
    console.log("autocomplete return value=>", value, location);
    setAddress(value);
    setCoordinates({
      _lat: location.lat,
      _long: location.lng,
    });
    setZipCode(zipCode);
  };

  useEffect(() => {
    setLoading(true);
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result=>", result);
          let temp = {
            latitude: result.latitude,
            longitude: result.longitude,
          };
          fetchHomeInforms(temp);
        },
        (error) => {
          console.log("error=>", error);
          fetchHomeInforms(currentPosition);
        }
      );
  }, []);

  const fetchHomeInforms = async (temp) => {
    try {
      const homeData = [];
      const response = db.collection("homes");
      const data = await response.get();
      let userData = [];
      if (!!currentUser) {
        const query = await db
          .collection("users")
          .where("uid", "==", currentUser.UserUid)
          .get();
        if (query.docs.length > 0) {
          userData = await query.docs[0].data();
          setUserType(userData.type);
        }
      }
      data.docs.map((item) => {
        let homeItem = item.data();

        if (!!currentUser && userData.type === "admin") {
          homeData.push(homeItem);
        } else if (!!currentUser && currentUser.UserUid === homeItem.sellerId) {
          homeData.push(homeItem);
        } else if (
          homeItem.status === "Active" ||
          homeItem.status === "Under Contract"
        ) {
          homeData.push(homeItem);
        }
        // homeData.push(item.data());
      });
      console.log("landing homedata=>", homeData);
      const homeDataDisplay = homeData
        .filter((item) => {
          if (item.squarefeet * 1 >= 1000) {
            return true;
          } else return false;
        })
        .map((item) => {
          const { _lat, _long } = item.coordinates;
          let formattedMiles = 0;

          if (_lat && _long) {
            let distances = getPreciseDistance(
              { latitude: _lat, longitude: _long },
              { latitude: temp.latitude, longitude: temp.longitude }
            );
            let miles = convertDistance(distances, "mi");

            if (miles < 10) {
              formattedMiles = miles.toFixed(1);
            } else {
              formattedMiles = miles.toFixed(0);
            }
          }

          return { ...item, information: formattedMiles };
        });
      homeDataDisplay.sort((a, b) =>
        a.information * 1 > b.information * 1 ? 1 : -1
      );
      setHomeInformsTemp(homeDataDisplay);
      const homeDataDisplay3 = [];
      if (homeDataDisplay.length > 3) {
        for (let i = 0; i < 3; i++) {
          homeDataDisplay3.push(homeDataDisplay[i]);
        }
      } else {
        for (let i = 0; i < homeDataDisplay.length; i++) {
          homeDataDisplay3.push(homeDataDisplay[i]);
        }
      }
      console.log("homeDataDisplay3=>", homeDataDisplay3);
      setHomeInformsTemp3(homeDataDisplay3);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const viewMoreHomes = () => {
    history.push("/gallery");
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <div className="w-full">
      <Nav />
      <div className="relative">
        <div className="block bg-header-texture bg-cover bg-no-repeat bg-center h-xl"></div>
        <div className="absolute block bg-dark-06 w-full h-full top-0 pt-16">
          <div className="text-center text-white font-extrabold text-3xl md:text-5xl mt-10">
            Your Home. Your Way. Sell SMART.
          </div>
          <div className="mt-6 flex justify-center">
            <div className="text-center text-white text-xl md:text-2xl px-8 max-w-xl">
              With over 4,000 successful closings, our team of Real Estate
              Attorney's provides the experience and protection you need.
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <div className="text-center text-white text-xl font-semibold md:text-2xl px-8 max-w-xl">
              Legal Documents Provided from Start to Closing <br /> Only 1%
              Listing Commission at Closing*. <br /> No Upfront Costs.
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <div className="text-center text-white text-xl md:text-2xl px-8 max-w-xl">
              *Call us and ask how we work to reduce overall commissions - (214)
              444-9743
            </div>
          </div>
          <div className="pt-6 flex justify-center">
            <div
              className="bg-primary text-white font-semibold px-5 py-3 rounded text-xl cursor-pointer"
              onClick={() =>
                signUpRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
            >
              Join SMART Today
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl relative block md:flex justify-between mx-auto md:h-2xl py-10 md:py-20">
        <div className="w-full md:w-1/2 inline-block px-4 md:pr-20">
          <Slider {...settings}>
            {images.map((item, index) => (
              <img
                src={item}
                alt={index}
                key={index}
                className="w-full h-80 object-cover"
              />
            ))}
          </Slider>
        </div>
        <div className="w-full md:w-1/2 px-4">
          <div className="text-2xl md:text-3xl mt-6 md:mt-0 font-bold text-dark">
            Why Choose SMART Property Marketing?
          </div>
          <div className="text-m text-dark mt-3 md:mt-5 md:leading-2 lg:leading-7">
            <p>
              When it comes to selling your home on your own, you need the
              expertise and legal protection to do it right. Our team of
              experienced real estate attorneys has helped over 4,000 homeowners
              successfully navigate the selling process, providing support from
              start to finish. With our comprehensive legal documents and
              services, you can rest assured that your sale is in good hands.
              Sell your home your way, while enjoying peace of mind and the
              confidence that comes with having a team of trusted professionals
              by your side.
            </p>
            <p className="mt-2">
              Our user-friendly, online platform provides an expansive set of
              services and tools to assist the For Sale by Owner client with
              selling their property independently.
            </p>
          </div>
          <div className="mt-3 md:mt-6 lg:mt-8 flex justify-left">
            <div
              className="bg-primary text-white text-m p-2 rounded cursor-pointer"
              onClick={() => history.push("/sell-your-home")}
            >
              Learn More About Selling the SMART Way
            </div>
          </div>
        </div>
      </div>
      <Calculator />
      <div className="max-w-7xl block items-center justify-between mx-auto my-2 pt-6 pb-12 sm:px-6 lg:px-8 mt-3">
        <div className="text-dark text-3xl font-bold text-center pt-6 pb-6">
          View Properties for Sale
        </div>
        <PuffLoader color={color} loading={loading} css={override} size={100} />
        {homeInformsTemp3 &&
          homeInformsTemp3.map((home, i) => (
            <div
              key={i}
              className="w-full md:w-1/2 lg:w-1/3 inline-block"
              onClick={() => handleClick(home)}
            >
              <HomeCard home={home} />
            </div>
          ))}
        <div className="flex justify-center pt-10">
          <div
            className="bg-primary text-white p-2 rounded text-sm cursor-pointer"
            onClick={() => viewMoreHomes()}
          >
            View More Properties
          </div>
        </div>
      </div>
      <div
        ref={signUpRef}
        className="max-w-7xl relative text-center mx-auto mb-16 px-6 md:px-32 lg:px-40"
      >
        <div className="text-black pt-8 md:pt-12 text-2xl md:text-4xl font-bold leading-snug">
          Become a SMART Seller today! 
        </div>
        <div className="text-black mt-5 mb-6 text-lg md:text-xl italic">
          Enter your details to create your free sellers account and get started
          building your profile.
        </div>
        {error !== null && (
          <div className="p-2 bg-red-600 w-full text-white text-center mb-3 rounded-md">
            {error}
          </div>
        )}

        <PuffLoader color={color} loading={loading} css={override} size={50} />

        <form onSubmit={handleFormSubmit}>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="firstname"
                className="block text-left text-dark text-base"
              >
                First Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="firstname"
                placeholder="First name"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="lastname"
                className="block text-left text-dark text-base"
              >
                Last Name
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="lastname"
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="email"
                className="block text-left text-dark text-base"
              >
                Email
              </label>
              <input
                type="email"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="email"
                placeholder="Enter email here"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label
                htmlFor="password"
                className="block text-left text-dark text-base"
              >
                Password
              </label>
              <input
                type="password"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="password"
                placeholder="Enter password here"
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 pr-2">
              <label
                htmlFor="phone"
                className="block text-left text-left text-dark text-base"
              >
                Phone # (Optional)
              </label>
              <input
                type="text"
                className={`w-full p-2 text-dark border border-primary outline-none focus:outline-none focus:ring rounded-md text-base transition duration-150 ease-in-out mb-4`}
                id="phone"
                placeholder="Enter Ph #"
              />
            </div>
            <div className="w-1/2 pl-2 mb-4 text-left">
              <PlacesAutocomplete parentCallback={callback} />
            </div>
          </div>

          <button
            type="submit"
            className={`mt-6 w-72 bg-primary py-2 px-4 text-base text-white rounded border border-primary`}
          >
            <span className="mr-2">CREATE MY FREE ACCOUNT</span>
            <i className="fa-arrow-right fa"></i>
          </button>
        </form>
        <div className="text-center mt-2">
          By creating an account, I agree to the{" "}
          <Link to="/terms" className="text-primary underline">
            Terms & Conditions.
          </Link>
        </div>
        <div className="text-center mt-2">
          Already have an account?&nbsp;&nbsp;
          <Link to="/login">
            <span className="text-primary underline">Sign in here</span>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
