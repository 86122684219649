import React from "react";
import Nav from '../components/Nav';
import Footer from "../components/Footer";

const PartnerPage = () => {
  return (
    <div className="w-full">
      <Nav />
      <img src="../assets/images/partner_with_smart.png" alt="Partner with SMART" className="w-full" />
      <div className="relative block lg:flex lg:flex-row-reverse justify-between mx-auto py-1 lg:py-2 lg:items-center lg:my-24">
        <div className="w-full lg:w-4/5 lg:px-16 lg:h-imgHeight">
          <img src="../assets/images/partner_how.png" alt="sell-home1" className="w-full h-full object-cover" />
        </div>
        <div className="w-full lg:w-1/2 p-10 md:px-24 lg:p-4 lg:absolute bg-white lg:border lg:border-black left-16">
          <p className="text-3xl font-bold">How SMART works with Real Estate Agents</p>
          <br />
          <div className="text-sm text-dark mt-1">
            SMART Property Marketing loves working with Real Estate Agents. SMART only handles the marketing of the Seller’s home and provides legal backing of the transaction all the way through Closing. As we do not list properties on MLS, we are valued and appreciated as a hidden in-house service that can be utilized similar to a "pocket-listing" scenario. Keeping an eye on these homes allows the independent Real Estate Agent to have an ace up their sleeve when they have a client that would benefit from the specific type of home, price or desired location.          </div>
          <br />
          <div className="text-sm text-dark mt-1">
            The relationship and commission between you and the buyer is completely in your hands. However, any Buyer who wants to engage with a SMART Seller, will need to complete a “Buyer Questionnaire and Consent Form”. This form allows SMART to review some specific buyer preferences, and proceed with a Personal and Banking Background Check of the Buyer to establish an estimated approved mortgage amount.
          </div>
          <br />
          <div className="text-sm text-dark mt-1">
            All SMART Sellers handle the entire transaction with any buyer, which includes reviewing and accepting offers, negotiating the sales price and any showings of the home. Our SMART Sellers have access to our SMART Real Estate Legal Team to support them throughout the entire process.           
          </div>
          <div className="text-sm text-dark mt-1">
            Interested in partnering with SMART? It’s simple! We require some basic information about you and the agency you represent, along with an agreement stating our terms. To get started, contact us through the website by starting a chat or sending an email to <a href="mailto:admin@smartpropertymktg.com">admin@smartpropertymktg.com</a>.           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default PartnerPage;
