import React, { useState, useRef, useEffect, createRef } from "react";
import { createPopper } from "@popperjs/core";

const bedroomsNumber = [
  { name: 'Any' },
  { name: '1+' },
  { name: '2+' },
  { name: '3+' },
  { name: '4+' },
  { name: '5+' },
  { name: '6+' },
]

const bathroomsNumber = [
  { name: 'Any' },
  { name: '1+' },
  { name: '2+' },
  { name: '3+' },
  { name: '4+' },
  { name: '5+' },
  { name: '6+' },
]

const FilterBedsBaths = ({parentCallback}) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const container = useRef(null);
  const [bedroomNum, setBedroomNum] = useState('Any');
  const [bathroomNum, setBathroomNum] = useState('Any');

  useEffect(()=> {
    const handleOutsideClick = (event) => {
      if(!!container.current) {
        if (!container.current.contains(event.target)) {
          if (!dropdownPopoverShow) return;
          setDropdownPopoverShow(false);
        }
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [dropdownPopoverShow, container]);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleBedRoomNumClick = (item) => {
    setBedroomNum(item);
  }

  const handleBathRoomNumClick = (item) => {
    setBathroomNum(item);
  }

  const handleClickReset = (e) => {
    e.preventDefault()
    setBedroomNum('Any')
    setBathroomNum('Any')    
  }
  
  const handleClickApply = (e) => {
    e.preventDefault();
    setDropdownPopoverShow(false);

    parentCallback(bedroomNum, bathroomNum);
  }

  return (
    <>
      <div ref={container} className="inline-block sm:flex flex-wrap mr-0 sm:mr-1 my-2">
        <div className="w-full sm:w-6/12 md:w-4/12 px-0">
          <div className="relative inline-flex align-middle w-full h-12">
            <button
              className={
                (dropdownPopoverShow ? "text-white bg-primary " : "text-primary bg-white") +
                "font-bold text-base pl-5 pr-10 py-1 rounded shadow hover:shadow-lg outline-none border border-primary focus:outline-none mr-1 mb-1 relative h-12"
              }
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              Beds&nbsp;&&nbsp;Baths
              <span className={
                (dropdownPopoverShow ? "text-white " : "text-primary ") +
                "z-10 h-full leading-snug font-normal absolute text-center absolute text-base items-center w-10 right-0"}>
                <i className={(dropdownPopoverShow ? "fa-caret-up " : "fa-caret-down ") + "fa"}></i>
              </span>
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                "bg-white text-base z-50 float-left p-4 list-none text-left rounded shadow-primary mt-1"
              }
              style={{ minWidth: "12rem", zIndex: 10 }}
            >
              <div className="block">
                <span className="text-dark font-medium">Bedrooms</span>
                <div className="w-80">
                  {bedroomsNumber.map((item, i) => (
                    <button 
                      key={i} 
                      onClick={()=>handleBedRoomNumClick(item.name)} 
                      className={
                        (bedroomNum === item.name ? "bg-primary text-white " : "bg-white text-dark ") + 
                        "border focus:border-primary focus:text-white font-light text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1"
                      } 
                      type="button"
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="py-2 block border-b">
                <span className="text-dark font-medium">Bathrooms</span>
                <div className="w-80">
                  {bathroomsNumber.map((item, i) => (
                    <button 
                      key={i} 
                      onClick={()=>handleBathRoomNumClick(item.name)} 
                      className={
                        (bathroomNum === item.name ? "bg-primary text-white " : "bg-white text-dark ") +
                        "border focus:border-primary focus:text-white font-light text-sm px-2 py-2 rounded outline-none focus:outline-none mr-1 mb-1"
                      } 
                      type="button"
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button onClick={handleClickReset} className="bg-white text-primary font-light text-lg px-2 py-2 outline-none focus:outline-none" type="button">
                  Reset
                </button>
                <button onClick={handleClickApply} className="bg-white text-primary font-light text-lg px-2 py-2 outline-none focus:outline-none" type="button">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBedsBaths;