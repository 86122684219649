import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import LandingPage from "../pages/LandingPage";
import GalleryPage from "../pages/GalleryPage";
import ListingPage from "../pages/ListingPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ProfilePage from "../pages/ProfilePage";
import MapPage from "../pages/MapPage";
import SellYourHomePage from "../pages/SellYourHomePage";
import BuyAHomePage from "../pages/BuyAHomePage";
import OriginStoryPage from "../pages/OriginStoryPage";
import PartnerPage from "../pages/PartnerPage";
import FAQPage from "../pages/FAQPage";
import TermsofUsePage from "../pages/TermsofUsePage";

function PublicRoute() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/signup" component={SignupPage} />
        <Route exact path="/forgot" component={ForgotPasswordPage} />
        <Route exact path="/gallery" component={GalleryPage} />
        <Route exact path="/profile/:id" component={ListingPage} />
        <Route exact path="/myprofile" component={ProfilePage} />
        <Route exact path="/map" component={MapPage} />
        <Route exact path="/sell-your-home" component={SellYourHomePage} />
        <Route exact path="/buy-a-home" component={BuyAHomePage} />
        <Route exact path="/our-story" component={OriginStoryPage} />
        <Route exact path="/partner" component={PartnerPage} />
        <Route exact path="/faq" component={FAQPage} />
        <Route exact path="/terms" component={TermsofUsePage} />
        <Route render={() => <Redirect to={{pathname: "/"}} />} />
      </Switch>
    </Router>
  );
}

export default PublicRoute;
