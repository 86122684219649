import React, { useRef, useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PropertyInformation = ({globalFeature, home, userType, own, onSave}) => {  
  const [show, setShow] = useState(false); 
  const [displayFeature, setDisplayFeature] = useState({name: '', description: '', features: []});
  const modalRef = useRef(null);
  const [checkedState, setCheckedState] = useState([
    { name: '', value: false },
  ]);
  const [descriptionValue, setDescriptionValue] = useState("");
  const { feature } = home

  useEffect(() => {
    if(!!feature) {
      let selectedFeature = {name: '', description: '', features: []};
      feature.forEach(item => {
        if(item.name === globalFeature.name) {
          selectedFeature = { ...item}
          setDisplayFeature(selectedFeature)
          setDescriptionValue(item.description)
        }
      }); 
      const newData = globalFeature.features.map((item1, i) => {
        let temp = {};
        if(handleCheck(item1, selectedFeature.features)) {
          temp = {name: item1, value: true}
        } else {
          temp = {name: item1, value: false}
        }
        return temp;      
      });
      setCheckedState(newData)
    } else {
      const newData = globalFeature.features.map(item1 => {
        return {name: item1, value: false}
      })
      setCheckedState(newData)
    }
  }, [feature]);

  const handleCheck = (val, arr) => {
    return arr.some(item => val === item);
  }

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleSaveClick = async() => {
    const checkedArr = []
    checkedState.map(item => {
      if(item.value) {
        checkedArr.push(item.name)
      }
    })

      let tempHomeData = {}
      let temp = {...globalFeature, description: descriptionValue, features: checkedArr}
      let initTemp = [{...globalFeature, description: descriptionValue, features: checkedArr}]

      if(!!feature) {
        const foundIndex = feature.findIndex(item => item.name === temp.name);
        let newFeatures = [];
        if (foundIndex > -1) {
          newFeatures = feature.map(item => {
            if(temp.name === item.name) {
              return {
                ...item,
                ...temp
              }
            }
            return { ...item};
          })
        } else {
          newFeatures = [...home.feature, temp];
        }
        tempHomeData = {...home, feature: newFeatures}
      } else {
        tempHomeData = {...home, feature: initTemp}
      }

      onSave(tempHomeData);

      setShow(false);
    
  }

  const onChangeCheckboxHandler = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? {name: item.name, value: !item.value} : {name: item.name, value: item.value}
    );
    setCheckedState([...updatedCheckedState]);
  }

  const onChangeDescripHandler = (e) => {
    let desc = e.currentTarget.value
    setDescriptionValue(desc)
  }

  return (
    <div className="w-1/2 mt-5 inline-block align-top">
      <span className="text-dark text-lg font-semibold relative">
        {globalFeature.name}
        {
          (userType === "Selling my home" || userType === "admin") && own &&
          <div onClick={() => showModal()} className="absolute top-0 -right-6 cursor-pointer">
            <img className="w-5" alt="edit" src="../assets/icons/edit.png"/>
          </div>
        }
      </span>
      <div className="w-full py-2">
        <span>{displayFeature.description}</span>
      </div>
      <div className="mt-2 block">
        {displayFeature.features.map((value, i)=> (
          <div key={i} className="inline-block border border-dark rounded px-1 mr-2 mb-2">
            <span>{value}</span>
          </div>
        ))}          
      </div>
      <Modal 
        ref={modalRef}
        open={show} 
        center 
        initialFocusRef={modalRef}
        onClose={hideModal}
        animationDuration={400}
      >
        <div className="text-center my-5">
          <h1 className="text-dark text-2xl font-semibold">{globalFeature.name}</h1>
        </div>        
        <form action="">
          <div className="w-full text-xl">
            <label htmlFor="description">
              Share more details about your home's {globalFeature.name}
              <textarea
                id='description'
                rows={2}
                cols={5}
                placeholder="Tell buyers what is special about your home. The more detail the better!"
                value={descriptionValue}
                className="w-full border border-primary rounded p-2 mt-2 outline-none focus:outline-none focus:ring"
                onChange={(e) => onChangeDescripHandler(e)}
              />
            </label>
          </div>
          <p className="text-dark text-xl mt-3">Check all the options that apply to your home:</p>
          {checkedState.map((item, i)=> (
            <div className="block mt-2 text-lg">
              <label key={i} className="">
                <input 
                  type="checkbox" 
                  id={`custom-checkbox1-${i}`}
                  className="form-checkbox"
                  checked={item.value}
                  onChange={() => onChangeCheckboxHandler(i)}
                />
                <span className="text-dark font-light ml-2 w-36">{item.name}</span>
              </label>
            </div>
          ))}
          <div className="text-center mt-5">
            <input 
              type="button" 
              value="Save" 
              onClick={handleSaveClick}
              className="w-1/2 bg-dark text-white font-light text-lg px-2 py-2 outline-none focus:outline-none"
            />
          </div>          
        </form>
      </Modal>
    </div>
  );
}
export default PropertyInformation;