import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import equal_logo from "../assets/equal-housing-opportunity-logo.jpeg";

import SMARTFairHousingPolicyPDF from "../assets/documents/SMARTFairHousingPolicy.pdf";
import SMARTCPNPDF from "../assets/documents/SMARTCPN.pdf";
import SMARTIABSPDF from "../assets/documents/SMARTIABS.pdf";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const navigationFirst = [
    { name: "Start a Chat", href: "#", current: false },
    { name: "FAQs", href: "/faq", current: false },
  ];

  const navigationSecond = [
    { name: "Terms of use", href: "/terms", current: false },
  ];

  const handleClick = (item) => {
    if (item.name === "Email Us" || item.name === "Start a Chat") {
      window.location = "mailto:help@smartpropertymktg.com";
    } else if (item.name === "Call Us") {
      window.location = "tel:234-567-8900";
    } //else if (item.name === "Start a Chat") {
    // }
  };

  return (
    <div className="w-full bg-light-blue-03 pb-10">
      <div className="max-w-7xl relative block md:flex justify-between mx-auto py-12">
        <div className="w-full md:w-2/4 pl-6">
          <div className="pl-2 flex-shrink-0">
            <Link to="/">
              <img className="w-52" src={logo} alt="Workflow" />
            </Link>
          </div>
          <div>
            <h3 className="text-dark text-lg mt-2">{`Copyright © ${currentYear} Smart Property`}</h3>
            <p className="text-dark text-sm mt-2">All rights reserved</p>
            <div className="mt-2 flex items-center">
              <a
                href={SMARTFairHousingPolicyPDF}
                target={"_blank"}
                className="text-dark hover:text-primary rounded-md text-xs underline"
                aria-current="equal hosing opportunity"
              >
                Equal Housing Opportunity
              </a>
              <img className="w-5 ml-4" src={equal_logo} alt="equal logo" />
            </div>
            <div className="mt-1">
              <a
                href={SMARTCPNPDF}
                target={"_blank"}
                className="text-dark hover:text-primary rounded-md text-xs underline"
                aria-current="equal hosing opportunity"
              >
                Texas Real Estate Commission Consumer Protection Notice
              </a>
            </div>
            <div className="mt-1">
              <a
                href={SMARTIABSPDF}
                target={"_blank"}
                className="text-dark hover:text-primary rounded-md text-xs underline"
                aria-current="equal hosing opportunity"
              >
                Texas Real Estate Commission Information About Brokerage
                Services
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:1/3 block md:flex px-10 md:px-20 justify-around mt-8 md:mt-0">
          <div className="">
            <h2 className="text-xl font-bold pb-4">SUPPORT</h2>
            {navigationFirst.map((item, index) => (
              <div className="pb-2" key={index}>
                <a
                  href={item.href}
                  className="text-dark hover:text-primary rounded-md text-lg font-medium"
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>
          <div className="">
            <h2 className="text-xl font-bold pb-4">LEGAL</h2>
            {navigationSecond.map((item, index) => (
              <div className="pb-2" key={index}>
                <a
                  href={item.href}
                  className="text-dark hover:text-primary rounded-md text-lg font-medium"
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>
          <div className="">
            <h2 className="text-xl font-bold pb-4">FOLLOW US</h2>
            <div className="flex items-center pb-2">
              <a
                className="p-2 cursor-pointer"
                href="https://www.facebook.com/SMARTPropertyMarketing"
              >
                <img
                  className="w-6"
                  alt="facebook"
                  src="../assets/icons/facebook.png"
                />
              </a>
              <a
                className="p-2 cursor-pointer"
                href="https://twitter.com/smartpropmktg"
              >
                <img
                  className="w-6"
                  alt="twitter"
                  src="../assets/icons/twitter.png"
                />
              </a>
              <a
                className="p-2 cursor-pointer"
                href="https://www.instagram.com/smartpropertymarketing"
              >
                <img
                  className="w-6"
                  alt="instagram"
                  src="../assets/icons/instagram.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
