import PublicRoute from "./routes/PublicRoute";
import { AuthProvider } from "./providers/Authentication";

function App() {
  return (
    <AuthProvider>
      <PublicRoute />
    </AuthProvider>
  );
}

export default App;
