import React, {
  memo,
  useState,
  useEffect,
} from "react";
import NumberFormat from 'react-number-format';
import logo from "../assets/logo.png";

const RangeSlider = memo(
  ({ onChange, value, ...sliderProps }) => {
    const [sliderVal, setSliderVal] = useState(100000);
    const [mouseState, setMouseState] = useState(null);  
    const [sixPercentVal, setSixPercentVal] = useState(6000);
    const [threePercentVal, setThreePercentVal] = useState(3000);
    const [feePercentVal, setFeePercentVal] = useState(1000);

    useEffect(() => {
      setSliderVal(value);
    }, [value]);

    const changeCallback = e => {
      setSliderVal(e.target.value);
      setSixPercentVal((e.target.value * 0.06).toFixed());
      setThreePercentVal((e.target.value * 0.03).toFixed());
      setFeePercentVal((e.target.value * 0.01).toFixed());
    };

    useEffect(() => {
      if (mouseState === "up") {
        onChange(sliderVal);
      }
    }, [mouseState]);
    return (
      <div className="my-2">
        <div className="flex items-center mb-5">
          <p className="text-xl md:text-3xl font-bold w-1/2 text-right">Sale Price:</p> 
          <p className="text-xl md:text-3xl font-bold ml-4 w-1/2 text-left">
            <NumberFormat
              value={sliderVal}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </p>
        </div>
        <input
          type="range"
          value={sliderVal}
          {...sliderProps}
          className="w-1/2"
          id="myRange"
          onChange={changeCallback}
          onMouseDown={() => setMouseState("down")}
          onMouseUp={() => setMouseState("up")}
        />
        <div className="text-base md:text-lg font-light w-full pt-2">
          *Calculations are estimates only
        </div>        
        <div className="flex justify-around items-center mt-12 px-10">
          <div className="text-dark bg-white w-40 md:w-52 h-32 md:h-36 border-primary rounded-2xl border-4 mr-2">
            <p className="text-md md:text-2xl mt-8 font-normal w-full text-center">
              6%
            </p>
            <p className="mt-2 text-md md:text-2xl font-bold w-full text-center">
              <NumberFormat
                value={sixPercentVal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </p>
          </div>
          <div className="text-white bg-fee-texture w-72 h-40 bg-no-repeat bg-center rounded-2xl relative">
            <div className="bg-white rounded-lg absolute -top-3 sm:-top-7 flex justify-center w-24 sm:w-40 md:w-52 mx-auto left-0 right-0">
              <img className="h-8 sm:h-12 xl:h-14" src={logo} alt="Workflow" />
            </div>            
            <p className="text-2xl md:text-3xl mt-10 font-bold w-full text-center">
              1.00%
            </p>
            <p className="mt-2 text-3xl md:text-4xl font-bold w-full text-center">
              <NumberFormat
                value={feePercentVal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </p>
          </div>
          <div className="text-dark bg-white w-40 md:w-52 h-32 md:h-36 border-primary rounded-2xl border-4 ml-2">
            <p className="text-md md:text-2xl mt-8 font-normal w-full text-center">
              3%
            </p>
            <p className="mt-2 text-md md:text-2xl font-bold w-full text-center">
              <NumberFormat
                value={threePercentVal}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
              />
            </p>
          </div>
        </div>
        <div className="text-base md:text-lg font-light w-full pt-4 px-2 sm:px-20 md:px-72">
          *6% is the standard commission fee for a Real Estate sale. The commission is typically paid by the seller and can be split between the seller and buyer's agent at 3% each.
        </div>
      </div>
    );
  }
);

export default RangeSlider;