/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import logo from "../assets/logo.png";
import { AuthContext } from "../providers/Authentication";
import { auth } from "../firebase";

const navigationLeft = [
  { name: "Sell Your Home", href: "/sell-your-home", current: false },
  { name: "Buy A Home", href: "/buy-a-home", current: false },
  { name: "Partner with SMART", href: "/partner", current: false },
  { name: "Origin Story", href: "/our-story", current: false },
  { name: "Help", href: "#", current: false },
  { name: "View Properties For Sale", href: "/gallery", current: false },
];

const navigationRightBefore = [
  { name: "Log in", href: "/login", current: false },
];

const navigationRightAfter = [
  { name: "My Profile", href: "/myprofile", current: false },
  // { name: "Log Out", href: "/", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Nav() {
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleClick = (item) => {
    if (item.name === "Log Out") {
      auth.signOut();
    }
  };

  const handleClickEmail = (e) => {
    e.preventDefault();
    window.location = "mailto:help@smartpropertymktg.com";
  };

  const handleClickCall = (e) => {
    e.preventDefault();
    window.location = "tel:1-234-567-8900";
  };

  const handleMouseOver = () => {
    setMenuIsOpen(true);
  };

  const handleMouseLeave = () => {
    setMenuIsOpen(false);
  };

  const onOptionClicked = () => {
    setMenuIsOpen(false);
  };

  const handleClickBanner = (e) => {
    e.preventDefault();
    window.location = "mailto:Owlvin@SMARTPropertyMktg.com";
  };

  return (
    <>
      <div className="w-full bg-light-blue-03 p-3">
        <p className="text-dark text-base text-center">
          Questions? Please contact us directly at&nbsp;
          <a
            href="#"
            className="text-dark hover:text-primary rounded-md text-base font-medium"
            onClick={handleClickBanner}
          >
            Owlvin@SMARTPropertyMktg.com
          </a>
        </p>
      </div>
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 xl:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center w-full">
              <div className="flex-shrink-0">
                <Link to="/">
                  <img className="h-12 xl:h-14" src={logo} alt="Workflow" />
                </Link>
              </div>
              <div className="hidden lg:block w-full">
                <div className="ml-2 flex items-baseline space-x-4 justify-between">
                  <div>
                    {navigationLeft.map((item, index) =>
                      item.name === "Help" ? (
                        <a
                          key={index}
                          className="text-primary lg:px-2 xl:px-3 py-2 rounded-md text-sm font-normal relative cursor-pointer"
                          onMouseOver={handleMouseOver}
                          onMouseLeave={handleMouseLeave}
                        >
                          Help
                          {menuIsOpen && (
                            <div className="absolute border border-solid border-primary z-1002 left-0 top-8 bg-white">
                              <div
                                className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                                onClick={onOptionClicked}
                              >
                                <a
                                  className="px-3 py-2 text-sm font-normal italic"
                                  href="#"
                                  onClick={handleClickEmail}
                                >
                                  <span className="ml-2">Email Us</span>
                                </a>
                              </div>
                              <div
                                className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                                onClick={onOptionClicked}
                              >
                                <a
                                  className="px-3 py-2 text-sm font-normal italic"
                                  href="#"
                                  onClick={handleClickCall}
                                >
                                  <span className="ml-2">Call Us</span>
                                </a>
                              </div>
                              <div
                                className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                                onClick={onOptionClicked}
                              >
                                <a
                                  className="px-3 py-2 text-sm font-normal italic"
                                  href="/faq"
                                >
                                  <span className="ml-2">FAQs</span>
                                </a>
                              </div>
                            </div>
                          )}
                        </a>
                      ) : item.name === "View Properties For Sale" ? (
                        <a
                          key={index}
                          href={item.href}
                          className="bg-primary text-white lg:px-2 xl:px-3 ml-2 py-2 rounded-md text-sm font-normal"
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ) : (
                        <a
                          key={index}
                          href={item.href}
                          className="text-primary lg:px-2 xl:px-3 py-2 rounded-md text-sm font-normal"
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      )
                    )}
                  </div>
                  {currentUser ? (
                    <div>
                      {navigationRightAfter.map((item, index) => (
                        <a
                          key={index}
                          href={item.href}
                          className="text-primary lg:px-2 xl:px-3 py-2 rounded-md text-sm font-normal"
                          aria-current={item.current ? "page" : undefined}
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {navigationRightBefore.map((item, index) => (
                        <a
                          key={index}
                          href={item.href}
                          className="text-primary lg:px-2 xl:px-3 py-2 rounded-md text-sm font-normal"
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-transparent inline-flex items-center justify-center p-2 rounded-md text-primary hover:text-white hover:bg-primary"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="lg:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigationLeft.map((item, index) =>
                  item.name === "Help" ? (
                    <a
                      key={index}
                      className={classNames(
                        item.current
                          ? "bg-primary text-white"
                          : "text-primary hover:bg-primary hover:text-white",
                        "block px-3 py-2 rounded-md text-sm font-normal relative"
                      )}
                      onMouseOver={handleMouseOver}
                      onMouseLeave={handleMouseLeave}
                    >
                      Help
                      {menuIsOpen && (
                        <div className="absolute border border-solid border-primary z-1002 left-0 top-9 bg-white">
                          <div
                            className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                            onClick={onOptionClicked}
                          >
                            <a
                              className="px-3 py-2 text-sm font-normal"
                              href="#"
                              onClick={handleClickEmail}
                            >
                              <span className="ml-2">Email Us</span>
                            </a>
                          </div>
                          <div
                            className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                            onClick={onOptionClicked}
                          >
                            <a
                              className="px-3 py-2 text-sm font-normal"
                              href="#"
                              onClick={handleClickCall}
                            >
                              <span className="ml-2">Call Us</span>
                            </a>
                          </div>
                          <div
                            className="text-primary hover:bg-primary hover:text-white w-40 py-2"
                            onClick={onOptionClicked}
                          >
                            <a
                              className="px-3 py-2 text-sm font-normal"
                              href="/faq"
                            >
                              <span className="ml-2">FAQs</span>
                            </a>
                          </div>
                        </div>
                      )}
                    </a>
                  ) : (
                    <a
                      key={index}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-primary text-white"
                          : "text-primary hover:bg-primary hover:text-white",
                        "block px-3 py-2 rounded-md text-sm font-normal"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  )
                )}
                {currentUser ? (
                  <>
                    {navigationRightAfter.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-primary text-white"
                            : "text-primary hover:bg-primary hover:text-white",
                          "block px-3 py-2 rounded-md text-sm font-normal"
                        )}
                        aria-current={item.current ? "page" : undefined}
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        {item.name}
                      </a>
                    ))}
                  </>
                ) : (
                  <>
                    {navigationRightBefore.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-primary text-white"
                            : "text-primary hover:bg-primary hover:text-white",
                          "block px-3 py-2 rounded-md text-sm font-normal"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </>
  );
}

export default Nav;
