import React from "react";
import Nav from '../components/Nav';
import Footer from "../components/Footer";
import Faq from "react-faq-component";

const data = {
  title: "",
  rows: [
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          Am I wasting my time by representing my own property?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          It’s true, wasted time could be a reason not to sell For Sale by Owner (FSBO). The perception is you aren’t able to stage your property, or take the perfect pictures of your home, or don’t know how to market your home effectively. It’s also perceived that you will waste time with “spending time showing the property, entertaining window shopper’s and door-kickers, and talking with agents who won’t take you seriously”. The fact is, SMART Property Marketing sets you up for success by providing you with the ability to create your own online profile that you can market exclusively from our website. And, SMART eliminates wasting your time by making sure every buyer you interact with is vetted with a personal banking and background check.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          I’ve heard the up front cost to FSBO can be thousands of dollars? What does it cost to engage with SMART Property Marketing?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          SMART Property Marketing charges the Seller a one-time engagement fee of $475.00 that includes a myriad of services.  Once the Service Agreement is executed, SMART will run an official title search on your property.  The title search will verify the property is in the name of the Seller and makes sure there are no secondary liens that could keep you from selling your home. This is the first step of the Real Estate legal protection SMART provides to the Seller.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          I’ve heard that choosing to sell FSBO doesn’t really save me money on a listing commission. Can you help explain why using SMART Property Marketing is a good idea?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          If you are even considering selling your property on your own, then you are the perfect client for SMART Property Marketing. It’s our opinion that no one knows your home or property better than you do. No one will disagree that it is a Sellers Market in 2022 and for a few years to come. SMART empowers the Seller with the marketing tools and the Real Estate legal protection to make their own decisions about how they want to market their home. You own the process. Our clients understand their own worth and will appreciate keeping up to 4.75% of the typical sales fees in their own pocket. <b><i>This is as much as saving approximately 75% from a standard 6% fee.</i></b>.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          Is SMART Property Marketing a Real Estate Agency?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          Absolutely not! The idea of For Sale By Owner has been around for decades. Those individuals weren’t Real Estate Agents and neither are we. SMART Property Marketing is a company designed to help that rare individual who already has the confidence to sell their own home, but just needs the tools to both market that property and get the peace of mind Real Estate legal protection from start to closing.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          SMART states you will “vet” every prospective buyer prior to them being able to be introduced to any SMART Seller.  What exactly does that entail?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          SMART Property is not a lender, therefore we do our very best to make basic determinations of each prospective buyer. With the buyers consent, SMART will conduct a soft inquiry background check to provide mid-level personal and banking information. A soft inquiry does not affect a credit score. The background check verifies the buyer’s identity and provides credit and banking history.  Based on a predetermined proprietary set of criteria SMART uses this information to determine participation that is either pass or fail.
          There are many financial variables and resources that can affect the buyers price range eligibility, above and beyond our control. With the buyer passing this vetting process,  SMART is confident to introduce serious and motivated buyers to our sellers. This assurance is what SMART Sellers want to know about their potential buyers, especially in a seller’s market. 
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          The perception of the typical FSBO sellers is that they are not serious about selling their properties and are often just testing the market or seeing if they can get some far-fetched price. What is SMART Property Marketing’s opinion of that notion? 
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          SMART Property Marketing believes highly motivated Sellers are serious enough to know what it takes to market and sell their own home and we give them serious reasons to do so.  We also believe that with the sale of a home being one of the largest transactions a person can make in their lives, that the Seller can market and sell that home as they see fit.  Your Home. Your Way.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          What tools does SMART provide to help me market my home?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          SMART Property Marketing keeps the entire process in-house, to ensure that we are helping to facilitate the Sellers' needs. SMART Sellers homes are posted exclusively on our website and Sellers are provided with tools to help them effectively market their home including a comprehensive and custom Seller’s Workbook, attractive For Sale yard sign, a Professional Marketing Flyer that can be printed or shared digitally and a custom link to your property profile to share throughout social media, email and via text message.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          What if the Buyer is represented by a Real Estate Agent?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          Real Estate Agents have worked for ages with the For Sale By Owner client. The Seller handles all of the home showings, the selling and negotiation of the Buyer Offers, so it really comes down to a simple introduction of the vetted, prospective Buyer to the SMART Seller. Any Buyer/Agent relationship is entirely separate from SMART, therefore any contract between those parties is their own business.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          What Real Estate legal protection do I have working with SMART and what does that include?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">   
          This is a huge value add with SMART Property Marketing. It’s easy to simply put a For Sale sign in your yard, but what about when you receive calls or an offer? Our service is backed by the SMART Real Estate Legal Team and provides guidelines, documents, contracts, and agreements that are customized for your specific transaction. We are committed to making sure SMART, the Seller and the Buyer are secured by this trail of documents to ensure that every legal step is protected. The SMART Real Estate Legal Team will arrange and provide our own Real Estate Attorney(s) for Closing.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          I’m interested in selling my home on my own. How do I know if I am selling my home with a solid asking price?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          It’s true. Pricing correctly when you sell a property can be tricky, especially if it’s a Sellers Market. Our job is NOT to help you determine an asking price for your home, but rather assist you with tools to market your property and provide the best Real Estate legal protection as possible for all parties during and after your sale. A COMP, which is an abbreviation for comparable sale, is a recently sold home in your area that’s similar to your home in location, size, condition and features. Sellers, especially those selling For Sale by Owner (FSBO), use COMPS to determine the right asking price for their home. You’ll want to be familiar with COMPS so you can make sure you’re comfortable with the sales price you want to post. You could always get an appraisal. Regardless of how you decide, remember, this is your home. In most cases, selling a home is the largest financial transaction a person will make in their lifetime. In 2022, properties are routinely selling for both above the appraisal and asking price every day. The asking price is the Seller’s decision.
        </div>,
    },
    {
      title: 
        <div className="text-md md:text-lg lg:text-xl">
          SMART Property Marketing provides Real Estate Attorneys to facilitate closing services. How does that benefit me?
        </div>,
      content: 
        <div className="text-md lg:text-lg mb-4">
          SMART Property Marketing recruits and uses only the best Real Estate Attorneys for our clients. Our business model relies on the expertise of these individuals, companies and affiliates to make sure each party; Seller, Buyer and SMART Property Marketing is legally protected before, during and beyond the sale. Therefore, we are adamant that ALL clients use our chosen attorney at the Title Closing. This ensures each party that the process is handled professionally and thoroughly.
        </div>,
    },
  ],
};

const styles = {
  bgColor: 'white',
  titleTextColor: "#378ABA",
  rowTitleColor: "#378ABA",
  rowContentColor: 'black',
  arrowColor: "#378ABA",
};

const config = {
  animate: true,
  tabFocus: true
};

const FAQPage = () => {
  return (
    <div className="w-full">
      <Nav />
      <img src="../assets/images/faq.png" alt="FAQs" className="w-full" />
      <div className="p-10 md:px-24 lg:px-32 lg:pt-20 text-md md:text-lg lg:text-xl">
        <div>Since 2008, the percentage of For Sale By Owner (FSBO) Sellers is between 8-10%.</div>
        <div>Obviously, FSBO is not for everyone, but it is for someone who has the confidence to sell on their own and wants to keep as much of their hard earned money as possible.</div>
        <div>View our most frequently asked questions about the SMART Property Marketing process below.</div>
      </div>
      <div className="px-10 md:px-24 lg:px-32 mb-10">
        <Faq
          data={data}
          styles={styles}
          config={config}
        />
      </div>
      <Footer />
    </div>
  )
};

export default FAQPage;
