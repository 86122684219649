import React from "react";

const Photo = ({ photo, margin, own, onClick }) => {
  const imgStyle = { margin: margin };

  const onDelete = (e) => {
    e.preventDefault();
    onClick(photo)
  }

  return (
    <div className="relative z-1002">
      <img
        style={imgStyle}
        {...photo}
        alt="img"
      />
      {own === true &&
        <button onClick={(e) => onDelete(e)} className="absolute flex top-2 right-2 cursor-pointer bg-dark text-white w-6 rounded-full justify-center">
          X
        </button>
      }
    </div>
  );
};

export default Photo;
