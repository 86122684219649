import React, { useState, useCallback, useMemo } from "react";
import RangeSlider from '../components/RangeSlider';

const Calculator = () => {
  const [parentVal, setParentVal] = useState(100000);

  const sliderValueChanged = useCallback(val => {
    setParentVal(val);
  });

  const sliderProps = useMemo(
    () => ({
      min: 100000,
      max: 2500000,
      value: parentVal,
      step: 5000,
      onChange: e => sliderValueChanged(e)
    }),
    [parentVal]
  );

  return (
    <div className="w-full bg-calculate-texture bg-cover bg-no-repeat bg-center">
      <div className="max-w-7xl relative text-center mx-auto">
        <div className="text-white pt-5 md:pt-8 text-lg md:text-xl leading-snug">
          Sell your property with SMART for as little as a
        </div>
        <div className="text-white pt-2 text-xl md:text-3xl font-bold leading-snug">
          1.00% Fee!
        </div>
        <div className="text-white mt-5 md:mt-6 text-lg md:text-xl">
          Adjust the SMART Fee Slider to compare traditional Real Estate Fees*
        </div>
        <div className="text-white py-2 font-bold">
          <RangeSlider {...sliderProps} />
        </div>
      </div>
    </div>
  )
};

export default Calculator;